import React from 'react'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useMemo } from 'react'
import LayoutDashboard from '../../../components/Dashboard/Layout'
import Loading from '../../../components/Loading/Loading'
import { API_URL } from '../../../utils/constant'
import { useRouter } from '../../../hooks/useRouter'

const Results = () => {
  const [schools, setSchools] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const router = useRouter()

  // const schoolID = router.query.schoolId
  // const classID = router.query.classId
  const submissionId = router.query.submissionId

  const fetchSchools = useMemo(
    () => async () => {
      setLoading(true) // set loading to true before making the API call
      const res = await fetch(
        `${API_URL}/forms/user/submissions?submission_id=${submissionId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      const data = await res.json()
      setSchools(data.map((item) => ({ ...item })))
      setLoading(false) // set loading to false after the API call has completed
    },
    [submissionId]
  )
  useEffect(() => {
    fetchSchools()
  }, [fetchSchools])

  return (
    <LayoutDashboard>
      <Typography
        component='div'
        className='lg-p-5 mt-5 pt-5'
        style={{ minHeight: '200vh' }}
      >
        <div
          className='container-fluid'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3
            className=' mx-lg-5 px-lg-5 mx-4'
            style={{
              color: 'gray',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            Application Details
          </h3>
          <div className=' text-muted centering'>
            Track your application status here and view your application
            details.
          </div>
          {loading ? <Loading /> : null}
          {!loading && schools.length === 0 ? (
            <div className='text-center centering'>
              You have not applied to any school yet
            </div>
          ) : null}
          {schools.map((item) => (
            <div
              className={`bg-white my-4 shadow mx-auto p-2`}
              style={{
                borderRadius: '25px',
                cursor: 'pointer',
                height: 'auto',
                width: '100%',
                margin: '2%',
              }}
              key={item.id + item.school_id + item.class_id}
            >
              <div className='py-2'>
                <table className='table table-borderless'>
                 
                    {item.values.map((item) => (

                     
                    <tbody key={item.id}>
                      
                      {item.fieldName === 'Class' ?  (
                          <tr key={item.fieldName}>
                        <td className='text-muted'>{item.fieldName}:</td>
                        <td>{item.className}</td>
                      </tr>

                        ) : (

                          <tr key={item.fieldName}>
                          <td className='text-muted'>{item.fieldName}:</td>
                          <td>{item.field_value}</td>
                        </tr>
                        )

                  }

                    </tbody>

                        
                      
                    ))}
                 
                </table>
              </div>
            </div>
          ))}
        </div>
      </Typography>
    </LayoutDashboard>
  )
}

export default Results
