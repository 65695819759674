import { Typography } from '@mui/material'
import React from 'react'
import styles from '../../../styles/Login.module.css'

const InputField = (props) => {
  return (
    <>
      <Typography component='div' className='my-2'key={props.key} >
        <Typography component='div'>
          <label className={styles.labelStyle} >{props.label}</label>
        </Typography>
        <Typography component='div'>
          <input
            name={props.name}
            type={props.inputType}
            className={props.classNameProp}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.value}
            id={props.id}
            
          />
        </Typography>
      </Typography>
    </>
  )
}

export default InputField
