import { Typography } from '@mui/material'
import React from 'react'
import styles from '../../../styles/Login.module.css'

const Card = ({ children }) => {
  return (
    <>
      <Typography
        component='div'
        className={styles.cardContainer}
        marginTop={5}
      >
        <main>{children}</main>
      </Typography>
    </>
  )
}

export default Card
