import React from 'react'
import ResponsiveHeader from './HeaderForPhone'
import Navbar from './Navbar'

const Header = () => {
  return (
    <>
     
      <Navbar />
    
    </>
  )
}

export default Header
