import React from 'react'
import FAQSComponent from '../../components/FAQS/FAQSComponent'
import Layout from '../../components/Layout/Layout'
import styles from '../../styles/SearchResults.module.css'

const FAQS = () => {
  return (
    <Layout>
      <div className={styles.mainContainer}>
        <div className='container mt-5 pt-5 min-vh-100'>
          <br />
          <div>
            <h1 className='text-center'>FAQS</h1>
            <div className=' text-center'>Frequently Asked Questions</div>
          </div>
          <br />
          <FAQSComponent />
        </div>
      </div>
    </Layout>
  )
}

export default FAQS
