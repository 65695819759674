import ImageGallery from 'react-image-gallery'
import styles from '../../styles/About.module.css'
import Header from '../../components/ForSchool/Partials/Header'
import PartnerSchools from '../../components/ForSchool/PartnerSchools'
import {Helmet} from "react-helmet";

const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
]

export default function About() {
  return (
    <>
      <Header />
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <section className={`${styles.heroContainer} jumbotron`}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className={`${styles.heroTitle} text-center`}>
                We make it easy to find the right school for your child
              </h1>
              <p className='text-center text-white'>
                We are a team of parents and educators who are passionate about
                helping parents find the right school for their child.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`${styles.aboutContainer} container-fluid`}
        style={{
          backgroundColor: '#51303b',
          fontFamily: 'Space Grotesk',
          color: '#fff',
          paddingTop: '100px',
        }}
      >
        <div className='row'>
          <div className='col-12'>
            <h1 className='text-center'>About Us</h1>
            <div className='container mb-5'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime
              illum laborum laudantium in deserunt architecto asperiores
              cupiditate perspiciatis harum quo accusamus aspernatur, magni
              incidunt nemo recusandae at. Culpa assumenda, molestiae doloribus
              similique sunt neque atque quaerat impedit id corporis excepturi
              voluptate? Sed deserunt iure eligendi molestiae est quasi harum
              nam.Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Maxime illum laborum laudantium in deserunt architecto asperiores
              cupiditate perspiciatis harum quo
            </div>
          </div>
        </div>
        <div
          className='row p-lg-4'
          style={{
            backgroundColor: '#51303b',
            color: '#fff',
          }}
        >
          <div className='col-12 p-lg-5'>
            <h1 className='text-center'>Our Mission</h1>

            <div className=' p-lg-3'>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime
              illum laborum laudantium in deserunt architecto asperiores
              cupiditate perspiciatis harum quo accusamus aspernatur, magni
              incidunt nemo recusandae at. Culpa assumenda, molestiae doloribus
              similique sunt neque atque quaerat impedit id corporis excepturi
              voluptate? Sed deserunt iure eligendi molestiae est quasi harum
              nam.Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <h1 className='text-center'>Our Team</h1>

              <div className='continer px-lg-5 py-lg-5'>
                <div className='row'>
                  <div className='col centering'>
                    <img
                      src='/test.jpg'
                      width={230}
                      alt=''
                      height={230}
                      className='rounded-circle'
                    />

                    <h3 className='text-center'>John Doe</h3>
                    <div className='text-center'>CEO</div>
                  </div>
                  <div className='col centering'>
                    <img
                      src='/test2.jpg'
                      width={230}
                      alt=''
                      height={230}
                      className='rounded-circle'
                    />
                    <h3 className='text-center'>John Doe</h3>
                    <div className='text-center'>CEO</div>
                  </div>{' '}
                  <div className='col centering'>
                    <img
                      src='/test3.jpg'
                      width={230}
                      alt=''
                      height={230}
                      className='rounded-circle'
                    />
                    <h3 className='text-center'>John Doe</h3>
                    <div className='text-center'>CEO</div>
                  </div>
                  <div className='col centering'>
                    <img
                      src='/test.jpg'
                      width={230}
                      alt=''
                      height={230}
                      className='rounded-circle'
                    />
                    <h3 className='text-center'>John Doe</h3>
                    <div className='text-center'>CEO</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <PartnerSchools />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#212121',
          }}
        >
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='text-center py-2'>
                  Our Events and Activities for the year
                </h1>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <ImageGallery
                  items={images}
                  lazyLoad={true}
                  autoPlay={true}
                  slideDuration={500}
                  slideInterval={4000}
                  showBullets={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
