import React from 'react'
import PageLayout from '../../components/SchoolDashboard/Layout/PageLayout'

const dashboard = () => {
  return (
    <PageLayout>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='container'>Coming soon</div>
    </PageLayout>
  )
}

export default dashboard
