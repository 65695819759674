import React from 'react'
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useMemo } from 'react'
import LayoutDashboard from '../../components/Dashboard/Layout'
import Loading from '../../components/Loading/Loading'
import { API_URL } from '../../utils/constant'
import { Link }  from 'react-router-dom'
import image from '../../assets/image/SearchResult/school1.jpg'
import useAuth from 'src/utils/useAuth'
import { Helmet } from 'react-helmet'

const Results = () => {
  const [schools, setSchools] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const { decoded } = useAuth()
  const [isEmailVerified, setEmailVerified] = React.useState(false)
  React.useEffect(() => {
    if (decoded.email_verified) {
      setEmailVerified(true);
    } else {
      setEmailVerified(false);
    }
  }, [decoded.email_verified]);

  const fetchSchools = useMemo(
    () => async () => {
      setLoading(true) // set loading to true before making the API call

      const res = await fetch(`${API_URL}/school/applied`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const data = await res.json()
      console.log(data)
      if(!isEmailVerified){
        setLoading(false)
      }
      setSchools(data.data.map((item) => ({ ...item })))
      setLoading(false) // set loading to false after the API call has completed
      
      
    },
    []
  )
  useEffect(() => {
    fetchSchools()
  }, [fetchSchools])

  return (
    <LayoutDashboard>
      <Helmet>
       <title>List of application</title>
     </Helmet>
      <Typography
        component='div'
        className='lg-p-5 pt-5'
        style={{ minHeight: '200vh' }}
      >
        <div
          className='container-fluid'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h3
            className=' mx-lg-5 px-lg-5 mx-4'
            style={{
              color: 'gray',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            Application List
          </h3>
          <div className=' text-muted centering'>
            Application list of schools you have applied to. Track your status.
          </div>
          {loading ? <Loading /> : null}
          {!loading && schools.length === 0 ? (
            <div className='text-center centering'>

              {isEmailVerified ? (
                 "You have not applied to any school yet"
              ) : (
                "You need to verify your email to start applying"
              )}         
             
            </div>
          ) : null}
          {schools.map((item) => (
            <div
              className={`bg-white my-4 shadow mx-auto p-2`}
              style={{
                borderRadius: '25px',
                cursor: 'pointer',
                height: 'auto',
                width: '100%',
                margin: '2%',
              }}
              key={item.name + item.school_id + item.class_id}
            >
              <div className=' d-flex'>
                <img
                  src={image}
                  alt={item.name}
                  width='95'
                  height='95'
                  className='rounded-circle shadow m-2'
                />
                <div className=' w-100 p-4'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div className=' w-100'>
                      <div
                        style={{
                          fontFamily: 'Space Grotesk',
                          fontWeight: 500,
                          fontSize: '1.2rem',
                        }}
                      >
                        {item.name}
                        <Typography component='div'>
                          Applied for : {item.className}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <Link
                        to={`/Dashboard/track-application?schoolId=${item.school_id}&classId=${item.class_id}&submissionId=${item.id}`}
                        style={{
                          textDecoration: 'none',
                        }}
                      >
                        <button
                          className='btn d-lg-flex d-none'
                          style={{
                            backgroundColor: '#F67DAA',
                            color: 'white',
                            borderRadius: '20px',
                            padding: '5px 20px',
                            fontWeight: 'bold',
                            fontSize: '0.8rem',
                            textDecoration: 'none',
                            width: '100%',
                          }}
                        >
                          Details
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Link
                  to={`/Dashboard/track-application?schoolId=${item.school_id}&classId=${item.class_id}&submissionId=${item.id}`}
                >
                  <button
                    className='btn d-lg-none d-block'
                    style={{
                      backgroundColor: '#F67DAA',
                      color: 'white',
                      borderRadius: '20px',
                      padding: '10px 20px',
                      fontWeight: 'bold',
                      fontSize: '0.8rem',
                      width: '100%',
                      margin: '3% 0',
                      textDecoration: 'none',
                    }}
                  >
                    Track Application
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Typography>
    </LayoutDashboard>
  )
}

export default Results
