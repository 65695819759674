import React from 'react'
import styles from '../../styles/notification.module.css'
import { Container, Typography } from '@mui/material'
import { useEffect } from 'react'

const Notifications = [
  {
    id: 1,
    title: 'Harvard University',
    text: 'You are seleted for the admission process. Please check your email for further details.',
  },
  {
    id: 2,
    title: 'University of California',
    text: 'You are seleted for the admission process. Please check your email for further details.',
  },
]

const Notification = () => {
  const [notification_type, setNotificationType] = React.useState('unread')

  useEffect(() => {
    // after 5 seconds, change the notification type to read
    setTimeout(() => {
      setNotificationType('read')
    }, 4000)
  }, [])
  return (
    <Typography component={'div'} className={styles.mainContainer}>
      <Container maxWidth='xl'>
        <Typography component={'div'} className={styles.notificationContainer}>
          <Typography component={'div'} className={styles.notification}>
            <Typography component={'div'} className={styles.notificationTitle}>
              Notifications
            </Typography>

            <hr />

            <Typography
              component={'div'}
              className={styles.notificationContent}
            >
              {Notifications.map((notification) => (
                <Typography
                  component={'div'}
                  className={
                    notification_type === 'unread'
                      ? styles.notificationItem
                      : styles.notificationItemRead
                  }
                  key={notification.id + notification.title + 1}
                >
                  <Typography
                    component={'div'}
                    className={
                      notification_type
                        ? styles.notificationContentItemTitleRead
                        : styles.notificationContentItemTitleUnread
                    }
                  >
                    {notification.title}
                  </Typography>
                  <Typography
                    component={'div'}
                    className={
                      notification_type
                        ? styles.notificationContentItemTextRead
                        : styles.notificationContentItemTextUnread
                    }
                  >
                    {notification.text}
                  </Typography>
                </Typography>
              ))}
            </Typography>
          </Typography>
        </Typography>
      </Container>
    </Typography>
  )
}

export default Notification
