import React from 'react'
import { Link }  from 'react-router-dom'
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import styles from '../../styles/ForSchool.module.css'


const HeroSection = () => {
  return (
    <>
      <div className={styles.add_bg_img}>
        <Container data-aos="fade-up" maxWidth='lg'>
          <Grid container direction='column'>
            <div className={styles.hero_text}>
              Manage and Track School Admission
            </div>
            <div className={styles.hero_subText}>
              {
                'Add your school to the platform and easily manage admission process'
              }
            </div>
            <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering my-lg-4'>
              <Link to='/school-login'>
                <button className={styles.signinButton}>Sign In</button>
              </Link>
              <Link to='/get-started'>
                <button className={styles.getStarted}>Get Started</button>
              </Link>
            </div>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default HeroSection
