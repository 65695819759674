import React from 'react'
import styles from '../../../styles/ForSchool.module.css'

const FormHead = (props) => {
  return (
    <div>
      <div
        className={styles.hero_text}
        style={{
          fontSize: '28px',
          marginBottom: '20px',
        }}
      >
        {props.itemTitle}
      </div>
    </div>
  )
}

export default FormHead
