import React from 'react'
import ResponsiveHeader from './HeaderForPhone'
import Navbar from '../../MarketingPage/Navbar'
import "src/styles/Header.module.css"
const Header = () => {
  return (
    <>
      {/* <div
        className='d-lg-block d-none'
        style={{
          position: 'sticky',
          top: '-12px',
          left: '-4px',
          width: '100%',
          height: '105px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          zIndex: 1000,
          marginBottom: '-5rem',
        }}
      /> */}
      <Navbar />
      {/* <ResponsiveHeader /> */}
    </>
  )
}

export default Header
