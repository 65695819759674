import React from 'react'
import Layout from '../../components/Layout/Layout'
import Notification from '../../components/Notification/Notification'

const Index = () => {
  return (
    <Layout>
      <Notification />
    </Layout>
  )
}

export default Index
