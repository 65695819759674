import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useAuth from '../../../utils/useAuth'
import logo from '../../../components/images/logo.svg'
import styles from '../../../styles/Header.module.css'
import { Link }  from 'react-router-dom'

function ResponsiveHeader() {
  const { token, decoded } = useAuth() || {}
  const [tokenStatus, setTokenStatus] = React.useState('')
  const [linkSet, setLinkSet] = React.useState('')

  /* Checking if the user is logged in or not. If the user is logged in, it will show the dashboard link.  */
  React.useEffect(() => {
    if (token) {
      /* This is checking if the user is a school admin or not. If the user is a school admin, it will show the school dashboard link.*/
      if (decoded.role === 'school-admin') {
        setTokenStatus('Dashboard')
        setLinkSet('/school-dashboard/application-list')
      } else {
        setTokenStatus('Dashboard')
        setLinkSet('/Dashboard')
      }
    } else {
      setTokenStatus('Login')
      setLinkSet('/login-as')
    }
  }, [token, decoded])

  return (
    <>
      <Typography
        component={'div'}
        className={styles.mobileView}
        style={{
          zIndex: 10000,
        }}
      >
        <AppBar position='static'>
          <Container maxWidth='xl' className='bg-white'>
            <Toolbar disableGutters>
              <Typography
                variant='div'
                noWrap
                component='a'
                href='/'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <img
                  src={logo}
                  width={240}
                  priority
                  height={35}
                  className={styles.logo}
                  alt='logo'
                />
              </Typography>

              <Typography
                variant='div'
                noWrap
                component='a'
                href='/'
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                }}
                className='bg-white'
              >
                <img
                  src={logo}
                  width={200}
                  height={40}
                  className={styles.logo}
                  alt='logo'
                />
              </Typography>

              <div>
                <div>
                  <Link
                    to={linkSet}
                    style={{
                      textDecoration: 'none',
                      backgroundColor: '#F875A7',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '5px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: '#F875A7',
                        border: 'none',
                        outline: 'none',
                      }}
                    >
                      {tokenStatus}
                    </button>
                  </Link>
                </div>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </Typography>
    </>
  )
}
export default ResponsiveHeader
