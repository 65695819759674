export const style = {
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
  bottom: "auto",
  height: "80vh",
  overflow: "auto",
};

export const classStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  p: 4,
  bottom: "auto",
  maxHeight: "80vh",
  overflow: "scroll",
  scrollbarColor: "red",
};

export const mapStyle = {
  position: "absolute",
  border: "none",
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "74%",
  bgcolor: "background.paper",
  boxShadow: 24,
};
