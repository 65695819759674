import React from 'react'
import { Grid } from '@mui/material'

const FormLayout = ({ children }) => {
  return (
    <div>
      <Grid container direction='column'>
        <div
          style={{
            backgroundColor: '#1C1C1C',
            paddingTop: '20px',
            paddingBottom: '20px',
            borderRadius: '20px',
            width: '100%',
            marginTop: '20px',
          }}
          className='centering'
        >
          {children}
        </div>
      </Grid>
    </div>
  )
}
export default FormLayout
