

import React, { createContext, useState, useEffect } from "react";

const FilterContext = createContext({});

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
  });

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterProvider };