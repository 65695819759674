import React from 'react'
import { Typography } from '@mui/material'
import { Formik } from 'formik'
import axios from 'axios'
import Button from '../Button/Button'
import Card from '../CardLayout/Card'
import styles from '../../../styles/Login.module.css'
import { API_URL } from '../../../utils/constant'

const ForgetPassword = () => {
  return (
    <Card className={styles.loginContainerMargin} noWrap>
      <Typography
        variant='h5'
        textAlign='center'
        className=' text-black-50 mx-3 py-3'
      >
        Forget Password
      </Typography>
      <Typography
        component={'div'}
        className='mx-3 py-3 text-center text-black-50'
      >
        Enter your email/mobile address below and we will send you a link to
        reset your password.
      </Typography>
      <Typography component='div' marginTop={2}>
        <label className={styles.labelStyle}>
          Email Address / Mobile Number
        </label>{' '}
        <span className=' text-danger'>*</span>
      </Typography>
      <Formik
        /* Setting the initial values of the form. */
        initialValues={{ email: '' }}
        /* Validating the form. */
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          return errors
        }}
        /* A function that is called when the form is submitted. */
        onSubmit={(values) => {
          const data = {
            userName: values.email,
          }
          axios
            .post(`${API_URL}/user/forgetPassword`, data)
            .then((res) => {
              console.log(res)
              console.log(res.data)
            })
            .catch((err) => {
              console.log(err)
            })
          window.location.href = '/password-reset?username=' + values.email
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {errors.email && touched.email && errors.email ? (
              <>
                <input
                  type='email'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={styles.errorStyle}
                  placeholder={errors.email && touched.email && errors.email}
                />
              </>
            ) : (
              <input
                name='email'
                type='email'
                className={styles.inputField}
                placeholder='Enter email/mobile number'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            )}
            <Button action='Reset Password' />
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default ForgetPassword
