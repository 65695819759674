import React from 'react'
import Login from '../Authentication/Login/Login'

const LoginFirst = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        backgroundColor: '#f7ecf0',
        marginTop: '-50px',
        marginBottom: '-50px',
      }}
    >
      <Login />
    </div>
  )
}

export default LoginFirst
