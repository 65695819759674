import { Container, Grid } from '@mui/material'
import React from 'react'
import AutoCompleteSearch from './AutoCompleteSearch'
import styles from '../../styles/HeroSection.module.css'



const SearchSchool = () => {
  return (
    <>
      <div className={styles.add_bg_img} >
        <Container maxWidth='lg' >
          <Grid container direction='column' className='flex'>
            <div className={styles.hero_text} >School Admissions Made Easy</div>
            <div className={styles.hero_subText}>
              Find the right school for your child. Research, Apply and Track
              Admissions anywhere, anytime
            </div>
            
              <AutoCompleteSearch />
           
          </Grid>
          
        </Container>

        {/* <ServicesComponent /> */}
      </div>
    </>
  )
}

export default SearchSchool
