import Image1 from "src/assets/ServicesIcons/search.svg"
import Image2 from "src/assets/ServicesIcons/up.svg"
import Image3 from "src/assets/ServicesIcons/check.svg"

const Services = [
  {
    title: 'Search and Explore',
    img: Image1,
    description:
      'Search for schools in your city and narrow down by Board, Classes-offered, Admission status and much more.',
  },
  {
    title: 'Shortlist Schools',
    img: Image2,
    description:
      'Shortlist schools easily that match your criteria. Come back later and complete your application.',
  },
  {
    title: 'Apply and Track Admission',
    img: Image3,
    description:
      'Avoid the hassle of filling out multiple forms. Apply to multiple schools with a single form and track your application status easily.',
  },
]

export default Services
