import React from 'react'
import { Typography } from '@mui/material'
import Header from '../Partials/Header/Header'
import Footer from '../Partials/Footer/Footer'
import ChatwootWidget from '../ChatwootWidget/ChatwootWidget'

function Layout({ children }) {
  return (
    <>
      <Typography component='div'>
        <Header />
        <main>{children}</main>
        {/* <ChatwootWidget /> */}
        <Footer />
      </Typography>
    </>
  )
}

export default Layout
