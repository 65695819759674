import React, { useState, useEffect, useContext } from 'react'
import { useRouter } from '../../hooks/useRouter'
import Moment from 'moment'
import { Box, Fade, Grid, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  MoreDetailsIcons,
  MoreDetailsIconsPhone,
} from './Icons/MoreDetailsIcons'
import ActionButtons from './Buttons/ActionButtons'
import Loading from '../Loading/Loading'
import FilterPhone from '../Filter/FilterPhone'
import styles from '../../styles/SearchResults.module.css'
import { API_URL } from '../../utils/constant'
import SearchSchools from '../Search/SearchSchools'
import { style, classStyle, mapStyle } from './result.styles.js'
import { FilterContext } from '../../context/FilterContext';
import SearchResultsImage from 'src/assets/image/SearchResult/school1.jpg'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Helmet } from 'react-helmet'

const Results = () => {
  // const schoolName = useRouter().query.keyword
  // const location = useRouter().query.location
  // const board = useRouter().query.board
  // const classQuery = useRouter().query.classs
  // const gender = useRouter().query.gender
  // const dayBoarding = useRouter().query.dayBoarding
  // const admissionStatus = useRouter().query.admissionStatus

  const router = useRouter()

  /* Setting the locale to English. */
  Moment.locale('en')

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [modalOpen, setModalOpen] = useState({})
  const [mapModalOpen, setMapModalOpen] = useState({})
  const [openClassModal, setOpenClassModal] = useState({})
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { filters } = useContext(FilterContext)

  const [token, setToken] = React.useState(Cookies.get("token")); //getting the token from the cookie and storing it in the state
  const decoded = token ? jwtDecode(token) : null

  /**
   * The function takes in an index as a parameter and sets the state of the modalOpen object to true
   * @param index - the index of the item in the array
   */
  const handleOpenModal = (index) => {
    setModalOpen({
      ...modalOpen,
      [index]: true,
    })
  }

  const handleClassModalOpen = (index) => {
    setOpenClassModal({
      ...openClassModal,
      [index]: true,
    })
  }

  const handleMapModalOpen = (index) => {
    setMapModalOpen({
      ...mapModalOpen,
      [index]: true,
    })
  }

  const getFormattedSchools = async (data) => {
    const formattedSchools = await Promise.all(
      data.map((item) => ({
        ...item,
        classMap: item.classes.map((item) => item?.name),
        locationForMap:
          item?.name +
          ', ' +
          item.address +
          ', ' +
          item.location.district_name +
          ', ' +
          item.location.state_name +
          ', ' +
          item.location.country_name,
        openAdmissions: item.classes.filter(
          (item) => item.admission_status === 1
        ),
        sortedClasses: item.classes
          .filter((item) => item.admission_status === 1)
          .concat(item.classes.filter((item) => item.admission_status !== 1)),
      }))
    )

    return formattedSchools
  }

  const fetchSchools = async () => {
    setLoading(true)
    const url = new URL(window.location)
    const keyword = url.searchParams.get('keyword')
    setSearchTerm(keyword)

    if (!keyword || keyword.length < 3) {
      setLoading(false)
      return
    }

    const {
      district,
      board,
      class: classes,
      admissionStatus,
      gender,
      residencyType,
    } = filters

    let searchQuery = `keyword=${keyword}`

    if (district !== null && district !== undefined) {
      searchQuery = `${searchQuery}&district=${district}`
    }

    if (board !== null && board !== undefined) {
      searchQuery = `${searchQuery}&board=${+board}`
    }

    if (classes !== null && classes !== undefined) {
      searchQuery = `${searchQuery}&class[]=${classes}`
    }

    if (admissionStatus !== null && admissionStatus !== undefined) {
      searchQuery = `${searchQuery}&admissionStatus=${+admissionStatus}`
    }

    if (gender !== null && gender !== undefined) {
      searchQuery = `${searchQuery}&gender=${gender}`
    }

    if (residencyType !== null && residencyType !== undefined) {
      searchQuery = `${searchQuery}&residencyType=${residencyType}`
    }

    const res = await fetch(`${API_URL}/school?${searchQuery}`)
    const data = await res.json()

    const formattedSchools = await getFormattedSchools(data)

    setSchools(formattedSchools)
    setLoading(false) // set loading to false after the API call has completed to hide the loading spinner
  }

  /* Calling the fetchSchools function whenever the fetchSchools function changes. */
  useEffect(() => {
    setTimeout(() => {
      fetchSchools()
    }, 300)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    setLoading(true)
    const url = new URL(window.location)
    const keyword = url.searchParams.get('keyword')
    setSearchTerm(keyword)

    if (!keyword) {
      setLoading(false)
      document.getElementById('search').setAttribute('value', null)
      setSchools([])
      return
    }
    // fetchSchools()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useRouter().query])

  /* Filtering the schools based on the query parameters. */
  // const filteredSchools =
  //   // if the schools is null, then return an empty array to avoid errors
  //   schools === null
  //     ? []
  //     : schools.filter((school) => {
  //         return (
  //           (!classQuery ||
  //             (classQuery + '')
  //               .split(',')
  //               .every((c) => new Set(school.classMap).has(c))) &&
  //           (!location || school.location.district_name === location) &&
  //           (!board || school.board === board) &&
  //           (!gender || school.gender_accepted === gender) &&
  //           (!dayBoarding || school.residency_type === dayBoarding) &&
  //           (!schoolName || school?.name.includes(schoolName)) &&
  //           (!admissionStatus || school.admission_status === admissionStatus)
  //         )
  //       })

  const filteredSchools = schools === null ? [] : schools

  return (
    <Typography
      component='div'
     
    >
       <Helmet>
       <title>Search for school  {searchTerm ? `- ${searchTerm}` : ''}</title>
    </Helmet>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          width: '95%',
          bgcolor: 'white',
          border: '2px solid #000',
          borderRadius: 4,
          overflow: 'scroll',
          margin: 'auto',
          height: '90%',
          marginTop: '20%',
        }}
      >
        <Box>
          <CloseIcon
            style={{
              float: 'right',
              cursor: 'pointer',
              color: 'gray',
            }}
            onClick={handleClose}
          />
          <FilterPhone handleClose={handleClose} />
        </Box>
      </Modal>
      <Typography
        component='div'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container >
          <div
            className={`${styles.cardComponent}  mx-auto `}
            style={{
              width: '100%',
              borderRadius: '30px',
              cursor: 'pointer',
            }}
          >
            <SearchSchools
              searchTerm={searchTerm}
              refreshSchools={fetchSchools}
            />
            <div
              style={{
                float: 'right',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: 'gray',
                cursor: 'pointer',
              }}
              className='d-lg-none d-flex'
              onClick={handleOpen}
            >
              Filter By &nbsp; <FilterAltIcon />
            </div>
            {!searchTerm && (
              <div className='mt-5'>
                <div className='centering'>
                  Use the search bar above to find schools.
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <>
              <div
                className={`my-4  mx-auto p-0`}
                style={{
                  width: '100%',
                  borderRadius: '25px',
                  cursor: 'pointer',
                }}
              >
                <Loading />
              </div>
            </>
          ) : filteredSchools.length > 0 && !loading ? (
            filteredSchools.map((item) => (
              <div
                className={`${styles.card} bg-white h-100 my-4 shadow mx-auto p-0`}
                style={{
                  width: '100%',
                  borderRadius: '25px',
                  cursor: 'pointer',
                }}
                key={item?.name}
              >
                <Grid
                  container
                  className='px-0'
                  style={{ height: '100%' }}
                  direction='column'
                >
                  {mapModalOpen[item.id] && (
                    <Modal
                      aria-labelledby='transition-modal-title'
                      aria-describedby='transition-modal-description'
                      open={mapModalOpen[item.id]}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={mapModalOpen[item.id]}>
                        <Box sx={mapStyle}>
                          <CloseIcon
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              color: 'gray',
                            }}
                            onClick={() => {
                              setMapModalOpen({
                                ...mapModalOpen,
                                [item.id]: false,
                              })
                            }}
                          />
                          <Typography component='div' sx={{ mt: 2 }} as='div'>
                            <Typography component='div' className='mapouter'>
                              <Typography
                                component='div'
                                className='gmap_canvas'
                              >
                                <iframe
                                  width='100%'
                                  height='560'
                                  id='gmap_canvas'
                                  src={`https://maps.google.com/maps?q=${item.locationForMap}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                  frameBorder='0'
                                  scrolling='yes'
                                  marginHeight='40'
                                  marginWidth='0'
                                ></iframe>
                              </Typography>
                            </Typography>
                          </Typography>
                        </Box>
                      </Fade>
                    </Modal>
                  )}
                  {openClassModal[item.id] && (
                    <Modal
                      aria-labelledby='transition-modal-title'
                      aria-describedby='transition-modal-description'
                      open={openClassModal[item.id]}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={openClassModal[item.id]}>
                        <Box sx={classStyle}>
                          <CloseIcon
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              color: 'gray',
                              marginBottom: '1rem',
                            }}
                            onClick={() => {
                              setOpenClassModal({
                                ...openClassModal,
                                [item.id]: false,
                              })
                            }}
                          />
                          <div
                            className='table'
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div
                                style={{
                                  fontSize: '0.9rem',
                                  fontFamily: 'Space Grotesk',
                                  textAlign: 'center',
                                }}
                              >
                                Class Name
                              </div>
                            </div>
                            <div style={{ flex: 2 }}>
                              <div
                                style={{
                                  fontSize: '0.9rem',
                                  fontFamily: 'Space Grotesk',
                                  textAlign: 'center',
                                }}
                              >
                                Duration
                              </div>
                            </div>
                            <div style={{ flex: 1 }}>
                              <div
                                style={{
                                  fontSize: '0.9rem',
                                  fontFamily: 'Space Grotesk',
                                  textAlign: 'end',
                                  textAlign: 'center',
                                }}
                              >
                                Action
                              </div>
                            </div>
                          </div>

                          {item.openAdmissions.length > 0 ? (
                            item.openAdmissions.map((c) => (
                              <div
                                className='border-top py-2'
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  textAlign: 'center',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                }}
                                key={c.id}
                              >
                                <div style={{ flex: 1 }}>
                                  <div
                                    style={{
                                      fontSize: '0.9rem',
                                      fontFamily: 'Space Grotesk',
                                      color: 'gray',
                                    }}
                                  >
                                    {c?.name}
                                  </div>
                                </div>
                                <div style={{ flex: 2 }}>
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      fontSize: '0.9rem',
                                      fontFamily: 'Space Grotesk',
                                    }}
                                  >
                                    {c.start_date
                                      ? Moment(c.start_date).format('ll')
                                      : null}
                                    {'  '}
                                    {'  '} - {'  '}
                                    {'  '}
                                    {c.end_date
                                      ? Moment(c.end_date).format('ll')
                                      : null}
                                  </div>
                                </div>
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                  <div
                                    style={{
                                      fontSize: '0.9rem',
                                      fontFamily: 'Space Grotesk',
                                    }}
                                  >
                                    {Moment(c.end_date).isBefore(
                                      Moment().format('YYYY-MM-DD')
                                    ) ? (
                                      <div
                                        style={{
                                          color: '#D31515',
                                          textDecoration: 'none',
                                          textAlign: 'end',
                                          fontSize: '0.9rem',
                                          textAlign: 'center',
                                          borderRadius: '5px',
                                          padding: '1rem',
                                          width: '100%',
                                        }}
                                      >
                                        Admission closed
                                      </div>
                                    ) : Moment(c.start_date).isAfter(
                                        Moment().format('YYYY-MM-DD') +
                                          ' 23:59:59'
                                      ) ? (
                                      <div
                                        style={{
                                          color: '#F89B2D',
                                          textDecoration: 'none',
                                          textAlign: 'center',
                                          fontSize: '0.9rem',
                                          padding: '1rem',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        Admission not started
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          textDecoration: 'none',
                                          textAlign: 'center',
                                          float: 'right',
                                          border: '1px solid #00B654',
                                          borderRadius: '5px',
                                          backgroundColor: '#fff',
                                          padding: '1rem',
                                          width: '100%',
                                          color: '#00B654',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {

                                          if(decoded && decoded?.role === 'school-admin') {

                                           return router.push(
                                             '/login'
                                            )

                                          } else {

                                           return router.push(
                                              '/apply/' +
                                                item.id +
                                                '?classID=' +
                                                c.id +
                                                '&className=' +
                                                c?.name
                                            )
                                          }

                                         
                                        }}
                                      >
                                        Apply Now
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className='border-top py-2'
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100px',
                              }}
                            >
                              <div
                                style={{
                                  fontSize: '0.9rem',
                                  fontFamily: 'Space Grotesk',
                                  color: 'gray',
                                }}
                              >
                                Admission Closed for this school
                              </div>
                            </div>
                          )}
                        </Box>
                      </Fade>
                    </Modal>
                  )}

                  {item.admission_status
                    ? item.admission_status === 'active' && (
                        <div className='activeRibbon'>
                          <span>Open</span>
                        </div>
                      )
                    : null}

                  {item.admission_status
                    ? item.admission_status === 'archived' && (
                        <div className='ribbon'>
                          <span>Closed</span>
                        </div>
                      )
                    : null}

                  <Grid
                    container
                    direction='row'
                    style={{ height: '100%', marginTop: '-50px' }}
                    alignItems='center'
                  >
                    <div className=' d-flex'>
                      <img
                        src={SearchResultsImage}
                        alt={item?.name}
                        width='95'
                        height='95'
                        className='rounded-circle shadow'
                        style={{
                          marginLeft: '20px',
                          marginRight: '10px',
                        }}
                      />
                      <Typography component='div' style={{ width: '85%' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginLeft: '10px',
                            marginTop: '20px',
                          }}
                        >
                          <div>
                            <Typography
                              variant='h5'
                              className='mb-0'
                              style={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 500,
                              }}
                            >
                              {item?.name}
                            </Typography>
                          </div>
                        </div>

                        {modalOpen[item.id] && (
                          <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                            open={modalOpen[item.id]}
                          >
                            <Fade in={modalOpen[item.id]}>
                              <Box sx={style}>
                                <CloseIcon
                                  style={{
                                    float: 'right',
                                    cursor: 'pointer',
                                    color: 'gray',
                                    marginBottom: '1rem',
                                  }}
                                  onClick={() => {
                                    setModalOpen({
                                      ...modalOpen,
                                      [item.id]: false,
                                    })
                                  }}
                                />

                                <div
                                  className='table'
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <div
                                      style={{
                                        fontSize: '1rem',
                                        fontFamily: 'Space Grotesk',
                                      }}
                                    >
                                      Available Classes for {item?.name}
                                    </div>
                                    <hr />
                                  </div>
                                </div>

                                {item.sortedClasses.map((c) => (
                                  <div
                                    className='py-2'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                    key={c.id}
                                  >
                                    <div style={{ flex: 1 }}>
                                      <div
                                        style={{
                                          fontSize: '1rem',
                                          fontFamily: 'Space Grotesk',
                                        }}
                                      >
                                        {c?.name}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Box>
                            </Fade>
                          </Modal>
                        )}

                        <Typography
                          variant='body2'
                          color='textSecondary'
                          style={{
                            fontSize: '0.8rem',
                            marginLeft: '10px',
                            fontFamily: 'Space Grotesk',
                          }}
                        >
                          {item.location.district_name},{' '}
                          {item.location.state_name},{' '}
                          {item.location.country_name}
                        </Typography>
                        <div>
                          <Typography
                            style={{
                              marginTop: '30px',
                            }}
                            component='div'
                          >
                            <Typography component='div'>
                              <MoreDetailsIcons
                                item={item}
                                handleOpenModal={() => handleOpenModal(item.id)}
                                handleClassModalOpen={() =>
                                  handleClassModalOpen(item.id)
                                }
                              />
                            </Typography>
                          </Typography>
                        </div>
                      </Typography>
                    </div>

                    <Typography
                      component='div'
                      className='d-flex flex-column align-items-end w-100'
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <ActionButtons
                        item={item}
                        handleOpenModal={() => handleOpenModal(item.id)}
                        handleClassModalOpen={() =>
                          handleClassModalOpen(item.id)
                        }
                      />
                      <MoreDetailsIconsPhone
                        item={item}
                        className='mx-0 mt-1'
                        handleMapModalOpen={() => handleMapModalOpen(item.id)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ))
          ) : null}
        </Grid>
      </Typography>
    </Typography>
  )
}

export default Results
