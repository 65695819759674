import React from 'react'
import styles from '../../styles/Contact.module.css'
import axios from 'axios'
import { Formik } from 'formik'
import { API_URL } from '../../utils/constant'
import ButtonRectangle from '../Authentication/Button/ButtonRectangle'

const ContactUs = () => {
  const [Iferrors, setIfErrors] = React.useState('')
  let successMessage = false



  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    
    

    // format the data object to send to the API
    const data = {
      name: values.name,
      schoolName: values.schoolName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      comment: values.comment,
    }
    axios
      .post(`${API_URL}/request-demo`, data)
      .then(() => {
        setSubmitting(false)
        successMessage = true
        resetForm()
        console.log('success')
        
       
        
      })
      .catch((err) => {
        /* Setting the error message to the error message returned from the server. */
        setIfErrors(err.response.data.err)
        setSubmitting(false)
        console.error(err)
      })
  }

  return (
    <div className={`${styles.wrapper}`}>
      <div
        className={`${styles.contactFormWrapper} d-flex justify-content-center`}
      >
        <Formik
         
          initialValues={{
            name: '',
            schoolName: '',
            email: '',
            phoneNumber: '',
            comment: '',
          }}
          validate={(values) => {
            const errors = {}
            // Name Validation
            if (!values.name) {
              errors.name = 'Name is required'
            }

            // School Name Validation
            if (!values.schoolName) {
              errors.schoolName = 'School Name is required'
            }

            // Email Validation
            if (!values.email) {
              errors.email = 'Email is required'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address'
            } else if (values.email.length > 50) {
              errors.email = 'Email must be less than 50 characters'
            } else if (
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                values.email
              )
            ) {
              errors.email = 'Invalid email address'
            }

            if (!values.phoneNumber) {
              errors.phoneNumber = 'Phone is required'
            } else if (!/^[0-9]+$/.test(values.phoneNumber)) {
              errors.phoneNumber = 'Phone Number must be only numbers'
            } else if (values.phoneNumber.length != 10) {
              errors.phoneNumber = 'Phone Number must be 10 digits'
            }


            return errors
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
           
          }) => (
            <form onSubmit={handleSubmit} className={`${styles.contactForm}`}>
              <h5 className={`${styles.title}`}>Contact us</h5>
              <p className={`${styles.description}`}>
                Ready to get started? Request for demo.
              </p>

              {successMessage ? (
                <div
                  role='alert'
                  style={{
                    color: '#57cd6b',
                    textAlign: 'center',
                    fontSize: '1rem',
                    padding: '10px',
                    marginBottom: '13px',
                    fontWeight: 'bold',
                  }}
                >
                 Email sent! We will get back to you as soon as possible.
                </div>
              ) : null}

              {Iferrors ? (
                <div
                  className={styles.errorStyle}
                  role='alert'
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '14px',
                    padding: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    backgroundColor: '#f8d7da',
                  }}
                >
                  {Iferrors}
                </div>
              ) : null}


            <div>

            {errors.name && touched.name && errors.name ? (

              <>
                <input
                  name='name'
                  type='text'
                  className={`${styles.formError} form-control rounded border-black mb-1 form-input`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />

                    <p className={styles.errorMessage}>
                      {errors.name && touched.name && errors.name}
                    </p>
                  </>
                ) : (
                  <input
                    name='name'
                    type='text'
                    className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}
                    placeholder='Your Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                )}
              </div>
              <div>
                {errors.email && touched.email && errors.email ? (
                  <>
                    <input
                      name='email'
                      type='email'
                      className={`${styles.formError} form-control rounded border-black mb-1 form-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />

                    <p className={styles.errorMessage}>
                      {errors.email && touched.email && errors.email}
                    </p>
                  </>
                ) : (
                  <input
                    name='email'
                    type='email'
                    className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}
                    placeholder='Your Email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                )}

                {/* <input id="email" type="email" className={`${styles.formControl} form-control rounded border-black mb-3 form-input`} placeholder="Your Email" required></input> */}
              </div>
              <div>
                {errors.phoneNumber &&
                touched.phoneNumber &&
                errors.phoneNumber ? (
                  <>
                    <input
                      name='phoneNumber'
                      type='text'
                      className={`${styles.formError} form-control rounded border-black mb-1 form-input`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                    />

            <p className={styles.errorMessage}>{errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}</p>
              </>
            
              ) : (
                <input
                  name='phoneNumber'
                  type='text'
                  className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}
                  placeholder='Phone Number'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              )}


              {/* <input id="phoneNumber"  type="text" className={`${styles.formControl} form-control rounded border-black mb-3 form-input`} placeholder="Mobile Number" required></input> */}
            </div>
            <div>
                
            {errors.schoolName && touched.schoolName && errors.schoolName ? (
              <>
              
          


              <input
                  name='schoolName'
                  type='text'
                  className={`${styles.formError} form-control rounded border-black mb-1 form-input`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.schoolName}
                />

            <p className={styles.errorMessage}>{errors.schoolName && touched.schoolName && errors.schoolName}</p>
              </>
            
              ) : (
                <input
                  name='schoolName'
                  type='text'
                  className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}
                  placeholder='School Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.schoolName}
                />
              )}

              {/* <input id="schoolName"  type="text" className={`${styles.formControl} form-control rounded border-black mb-3 form-input`} placeholder="School Name" required></input> */}
        
          
                <textarea
                  name='comment'
                  type='text'
                  className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}
                  rows="5" 
                  cols="30"
                  placeholder='Please feel free to add additional information, such as your preferred contact time and the features you are seeking in our platform'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment}
                />
             
{/* 
              <textarea id="message" className={`${styles.formControl} form-control rounded border-black mb-3 form-input`}  rows="5" cols="30" placeholder="Please feel free to add additional information, such as your preferred contact time and the features you are seeking in our platform" ></textarea> */}
            </div>
            <div className={`${styles.submitButtonWrapper}`}>
            <ButtonRectangle
     type="reset"
     text="clear all" action='Request Demo' value="Request free demo"/>
              {/* <input type="submit" value="Request free demo"></input> */}
            </div>

          </form>

          )}
        </Formik>

        <p className={styles.additionalInfo}>
          Feel free to write to us directly info@admissionpedia.com
          AdmissionPedia Pvt. Ltd No. 240, 6th St, Palkalai Nagar, Palavakkam,
          Chennai, Tamil Nadu 600041, India
        </p>
      </div>
    </div>
  )
}

export default ContactUs
