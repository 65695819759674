import React from 'react'
import List from '../../../components/SchoolDashboard/ApplicationList/List'
import PageLayout from '../../../components/SchoolDashboard/Layout/PageLayout'

const ApplicationList = () => {
  return (
    <>
      <PageLayout>
        <div className='conatiner'>
          <div className='row mt-5 '>
           
              <List />
           
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default ApplicationList
