import { Link }  from 'react-router-dom'
import React, { useEffect } from 'react'
import styles from '../../../styles/Navbar.module.css'
import cookie from 'js-cookie'
import jwt_decode from "jwt-decode";
import { API_URL } from '../../../utils/constant'

const Navbar = () => {
  const [schools, setSchools] = React.useState([])
  const token = cookie.get('token')
  const decoded = token ? jwt_decode(token) : null
  const schoolId = decoded.school_id

  const [isLogged, setIsLogged] = React.useState(false)

  useEffect(() => {
    if (token) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }, [token])

  const fetchSchools = React.useMemo(
    () => async () => {
      const res = await fetch(`${API_URL}/school/${schoolId}`)
      const data = await res.json()
      setSchools(data)
    },
    [schoolId]
  )

  /* Calling the fetchSchools function whenever the fetchSchools function changes. */
  React.useEffect(() => {
    fetchSchools()
  }, [fetchSchools])

  return (

    <nav className={styles.Navbar}>
      <div className={styles.logo}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.91)",
          }}
          className={styles.LogoStyle}
        >
          <span>Admission</span>
          <span className={styles.pedia}>Pedia</span>
        </Link>
      </div>

      <div
        className={
           styles.navbarElementsSchool
        }
      >
        <ul>
          <li className={styles.navbarItem}>
            <h4 className={styles.navbarItemSchoolName} to="/school-dashboard/application-list">
            {schools.name}
            </h4>
            <p className={styles.navbarItemSchool}>
            {decoded.username}
            </p>
          </li>
         
      

        </ul>
      </div>

    </nav>
    // <div className='container '>
    //   <div className='row '>
    //     <div className='col-12 '>
    //       <div className=''>
    //         <div
    //           style={{
    //             position: 'sticky',
    //             zIndex: 1000,
    //              height: '100px',
    //             color: 'rgba(0, 0, 0, 0.91)',
    //           }}
    //         >
    //           <div className={styles.NavbarStyle}>
    //             <div className='navbarItem'>
    //               <Link
    //                 to='/'
    //                 style={{
    //                   textDecoration: 'none',
    //                   color: 'rgba(0, 0, 0, 0.91)',
    //                 }}
    //                 className={styles.LogoStyle}
    //               >
    //                 <span>Admission</span>
    //                 <span className={styles.pedia}>Pedia</span>
    //               </Link>
    //             </div>
    //             <div>
    //               <b>
    //                 {setIsLogged ? (
    //                   <>
    //                     <Link
    //                       to='/school-dashboard'
    //                       className=' text-decoration-none fs-4 text-muted'
    //                     >
    //                       {schools.name}
    //                     </Link>
    //                     <div className='text-decoration-none fw-normal'>
    //                       {decoded.username}
    //                     </div>
    //                   </>
    //                 ) : (
    //                   <Link to='/login-as'>Login</Link>
    //                 )}
    //               </b>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Navbar
