import React from 'react'
import ChatwootWidget from '../../ChatwootWidget/ChatwootWidget'
import Footer from '../../Partials/Footer/Footer'
import Header from '../Partials/Header'

const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      {/* <ChatwootWidget /> */}
      <Footer />
    </>
  )
}

export default MainLayout
