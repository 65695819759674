import React from 'react'
import { Typography } from '@mui/material'
import { Formik } from 'formik'
import axios from 'axios'
import styles from '../../styles/Login.module.css'
import Fields from '../../components/Authentication/Fields/InputField'
import Card from '../../components/Authentication/CardLayout/Card'
import Button from '../../components/Authentication/Button/Button'
import Layout from '../../components/Layout/Layout'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import { useRouter } from '../../hooks/useRouter'
import { API_URL } from '../../utils/constant'
import Modal from '@mui/material'

const Reset = () => {
  const [Iferrors, setIfErrors] = React.useState('')

  const router = useRouter()

  const username = router.query.username


  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      userName: username,
      // if otp is a number make sure to convert it to string
      otp: values.otp.toString(),
      password: values.password,
    }

    axios
      .post(`${API_URL}` + `/user/resetPassword`, data)
      .then((res) => {
        alert('Password reset successfully')
        router.push('/login')
      })
      .catch((err) => {
       
        setIfErrors(err.response.data)
        setSubmitting(false)
       
      })
      
  }
  return (
    <>
      <Layout>
        <AuthenticationPageLayout>
          <br />
          <br />
          <br />
          <br />
          <Card className={styles.loginContainerMargin} noWrap>
            <Typography variant='h5' className='mx-3 py-2' textAlign='center'>
              Reset Password
            </Typography>
            <Formik
              /* Setting the initial values of the form. */
              initialValues={{
                otp: '',
                password: '',
                confirmPassword: '',
              }}
              /* Validating the form. */
              validate={(values) => {
                const errors = {}


                if (!values.otp) {
                  errors.otp = 'Required'
                } else if (values.otp.length !== 6) {
                  errors.otp = 'OTP must be 6 numbers'
                } else if (!/^[0-9]+$/.test(values.otp)){
                  errors.otp = 'OTP must be numbers only'
                }

                if (!values.password) {
                  errors.password = 'Required'
                }

                if (!values.confirmPassword) {
                  errors.confirmPassword = 'Confirm Password is required'
                } else if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = 'Password does not match'
                }

                return errors
              }}
              /* A function that is called when the form is submitted. */
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  {Iferrors ? (
                    <div
                      className={styles.errorStyle}
                      role='alert'
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        backgroundColor: '#f8d7da',
                      }}
                    >
                      {Iferrors}
                    </div>
                  ) : null}

                  {errors.otp && touched.otp && errors.otp ? (
                    <>
                      <Fields
                        label='otp'
                        inputType='text'
                        name='otp'
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.otp}
                        errors='otp'
                        classNameProp={styles.errorStyle}
                      />

                    <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.otp && touched.otp && errors.otp}
                      </Typography>
                    </>
                  ) : (
                    <Fields
                      label='otp'
                      placeholder='Enter 6 digit otp'
                      inputType='text'
                      name='otp'
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.otp}
                      errors='otp'
                      classNameProp={styles.inputField}
                    />
                  )}

                  {errors.password && touched.password && errors.password ? (
                    <>
                      <Fields
                        label='Password'
                        placeholder={
                          errors.password && touched.password && errors.password
                        }
                        name='password'
                        inputType='password'
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.password}
                        errors='password'
                        classNameProp={styles.errorStyle}
                      />
                       <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.password && touched.password && errors.password}
                      </Typography>
                      
                    </>
                  ) : (
                    <>
                      <Fields
                        label='Password'
                        placeholder='Enter new password'
                        name='password'
                        inputType='password'
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.password}
                        errors='password'
                        classNameProp={styles.inputField}
                      />
                    </>
                  )}

        {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword ? (
                    <>
                      <Fields
                        label='Confirm Password'
                        placeholder={
                          errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                        }
                        name='confirmPassword'
                        inputType='password'
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.confirmPassword}
                        errors='password'
                        classNameProp={styles.errorStyle}
                      />
                        <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Fields
                        label='Confirm Password'
                        placeholder='Confirm Password'
                        name='confirmPassword'
                        inputType='password'
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.confirmPassword}
                        errors='password'
                        classNameProp={styles.inputField}
                      />
                    </>
                  )}

                  <Button action='Reset' />
                </form>
              )}
            </Formik>
          </Card>
        </AuthenticationPageLayout>
      </Layout>
    </>
  )
}

export default Reset
