import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/Layout/Layout'
import PrivacyPolicy from 'src/components/PrivacyPolicy/PrivacyPolicy'

const index = () => {
  return <>
  <Layout>

    <Helmet>
    <title>Privacy Policy</title>
    </Helmet>
    <PrivacyPolicy></PrivacyPolicy>

  </Layout>
  </>
}

export default index
