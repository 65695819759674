import * as React from "react";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Typography } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useRouter } from "../../hooks/useRouter";
import styles from "../../styles/SearchButton.module.css";
import { API_URL } from "../../utils/constant";

const ValidationSchema = yup.object().shape({
  search: yup.string().required("Required"),
});

export default function SearchSchools({ refreshSchools, searchTerm }) {
  const [inputValue, setInputValue] = React.useState(searchTerm || "");
  const [suggestions, setSuggestions] = React.useState([]);
  const [inputClicked, setInputClicked] = React.useState(false);
  const [error, setError] = useState("");

  const router = useRouter();

  /* Setting the input value to the query parameter id. */
  useEffect(() => {
    const url = new URL(window.location);
    const keyword = url.searchParams.get("keyword");
    setInputValue(keyword);

    if (keyword && keyword.length < 3) {
      setError("Please enter atleast 3 characters");
    }
  }, []);

  useEffect(() => {
    const url = new URL(window.location);
    const keyword = url.searchParams.get("keyword");

    setInputValue(keyword);

    if (!keyword) {
      document.getElementById("search-form").reset();
    }
  

    if (keyword && keyword.length < 3) {
      setError("Please enter atleast 3 characters");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useRouter().query, searchTerm]);

  return (
    <Typography
      component="div"
      style={{
        fontFamily: "Space Grotesk",
      }}
    >
      <Typography component="div">
        <form
          id="search-form"
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center "
            style={{
              width: "100%",
            }}
          >
            <input
              className={styles.searchResultSearchBar}
              type="text"
              name="search"
              value={inputValue}
              autoComplete="off"
              id="search"
              placeholder="Enter school or location to view results"
              onChange={(e) => {
                setInputValue(
                  /* Making the first letter of the input value uppercase. */
                  e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1)
                );
                setError("");
                // setInputClicked(false) &&
                // handleChange(e)
              }}
              // onClick={() => setInputClicked(true)}
            />

            <button
              className={styles.searchButtonResult}
              onClick={(e) => {
                e.preventDefault();

                if (!inputValue || inputValue.length < 3) {
                  setError("Please enter atleast 3 characters");
                  return;
                }

                const url = new URL(window.location);
                url.searchParams.set("keyword", inputValue);
                window.history.pushState(null, "", url.toString());

                if (refreshSchools) {
                  refreshSchools();
                }
              }}
            >
              <SearchOutlinedIcon />
            </button>
          </div>

          {/* {suggestions && suggestions.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    width: '100%',
                    height: `
                      ${suggestions.length > 5 ? '300px' : 'auto'}`,
                    overflow: 'auto',
                    zIndex: '100',
                    backgroundColor: 'white',
                    borderRadius: '0 0 25px 25px',
                    fontSize: '1rem',

                    fontFamily: 'Space Grotesk',
                  }}
                  className='shadow'
                >
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.value}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                      }}
                      className='hoverColor'
                      onClick={() => {
                        setInputValue(suggestion.label)
                        setSuggestions([])
                      }}
                    >
                      {suggestion.label}, {suggestion.location}
                    </div>
                  ))}
                </div>
              )} */}
        </form>

        <div
        // className="d-flex justify-content-center align-items-center "
        // style={{
        //   width: "100%",
        // }}
        >
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </Typography>
    </Typography>
  );
}
