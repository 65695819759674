import React from 'react'
import { Link }  from 'react-router-dom'
import ArticleIcon from '@mui/icons-material/Article'
import StarsIcon from '@mui/icons-material/Stars'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import { useRouter } from '../../hooks/useRouter';
import styles from '../../styles/DashboardSidebar.module.css'

const SideBarItems = [
  {
    name: 'Dashboard',
    link: '/Dashboard',
    icon: <DashboardIcon />,
  },
  {
    name: 'List of Application',
    link: '/Dashboard/applied-schools',
    icon: <ArticleIcon />,
  },
  {
    name: 'Shortlisted Schools',
    link: '/Dashboard/shortlisted-schools',
    icon: <StarsIcon />,
  },
  {
    name: 'My Profile',
    link: '/Dashboard/my-profile',
    icon: <SentimentSatisfiedAltIcon />,
  },
  {
    name: 'Logout',
    link: '/Dashboard/logout',
    icon: <LogoutIcon />,
  },
]

const DashboardSidebar = () => {
  const router = useRouter()
  const currentUrl = router.pathname
  return (
    <div className='container'>
      <div className=''>
        <div className=' mt-5 pt-5'>
          <div className={styles.sidebar}>
            {SideBarItems.map((item, index) => (
              <div key={index}>
                <Link
                  to={item.link}
                  className={
                    currentUrl === item.link
                      ? styles.sidebarItemActive
                      : styles.sidebarItem
                  }
                >
                  <div className={styles.sidebarItemIcon}>{item.icon}</div>
                  <div className={styles.sidebarItemName}>{item.name}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardSidebar
