import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRouter } from "../../../hooks/useRouter";
import axios from "axios";
import Card from "../CardLayout/Card";
import OtpInput from "./OtpInput";
import Button from "../Button/Button";
import { API_URL } from "../../../utils/constant";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import styles from "../../../styles/Contact.module.css";
import ButtonRectangle from "../Button/ButtonRectangle";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const OTP = () => {


  // Get the URLSearchParams object
const params = new URLSearchParams(window.location.search);

// Get the token value from the query string
  const token = params.get('token');

  console.log(token); // Output the token value to the console
  const decoded = token ? jwtDecode(token) : null
  const email = decoded.username

  const [Iferrors, setIfErrors] = React.useState("");

  const [resend, setResend] = useState(false);
  let successMessage = false;

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    // format the data object to send to the API
    const data = {
      email: email,
      otp: values.otp,
      source: "email",
    };
    axios
      .get(`${API_URL}/user/otpValidate`, { params: data })
      .then(() => {
        setSubmitting(false);
        successMessage = true;
        window.location = '/login'
        resetForm();
        console.log("success");
      })
      .catch((err) => {
        /* Setting the error message to the error message returned from the server. */
        setIfErrors(err.response.data);
        setSubmitting(false);
        console.error(err);
        
      });
  };


  function resendOtp() {
   
    const data = {
      email: email,
      source: "email",
    };
    axios
      .get(`${API_URL}` + `/user/otpResend`, { params: data })
      .then((response) => {
        console.log(response.data);
        setResend(true);

        setTimeout(() => {
          setResend(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Card className="mx-auto" noWrap>
      <Typography component="div" className="text-center">
        <Typography variant="h4" className="text-center mb-1">
          Confirm your email address
        </Typography>
      </Typography>
      <Typography component="div" className="text-center">
        <Typography component="div" className="text-center text-black-50">
          Enter the OTP sent to your email address
        </Typography>
      </Typography>

      {resend ? (
        <div
          role="alert"
          style={{
            color: "#57cd6b",
            textAlign: "center",
            fontSize: "1rem",
            padding: "10px",
            marginBottom: "13px",
            fontWeight: "bold",
            transition: "all 1s ease-in-out",
          }}
        >
          Email Resent! Check your emails.
        </div>
      ) : null}

      <Typography component="div">
        <Formik
          initialValues={{
            otp: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.otp) {
              errors.otp = "Required";
            } else if (values.otp.length !== 6) {
              errors.otp = "OTP must be 6 numbers";
            } else if (!/^[0-9]+$/.test(values.otp)) {
              errors.otp = "OTP must be numbers only";
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              {successMessage ? (
                <div
                  role="alert"
                  style={{
                    color: "#57cd6b",
                    textAlign: "center",
                    fontSize: "1rem",
                    padding: "10px",
                    marginBottom: "13px",
                    fontWeight: "bold",
                  }}
                >
                  <Link style={{ color: "#57cd6b" }} to={"/login"}>
                    {" "}
                    Email Verified! 
                  </Link>
                </div>
              ) : null}

              { !successMessage && Iferrors ? (
                <div
                  className={styles.errorStyle}
                  role="alert"
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "10px",
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    backgroundColor: "#f8d7da",
                  }}
                >
                  {Iferrors}
                </div>
              ) : null}

              {errors.otp && touched.otp && errors.otp ? (
                <>
                  <input
                    name="otp"
                    type="text"
                    className={`${styles.formError} text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                  />
                  {/* 
                      <p className={styles.errorMessage} style={{textAlign:'center'}}>
                        {errors.otp && touched.otp && errors.otp}
                      </p> */}
                </>
              ) : (
                <input
                  name="otp"
                  type="text"
                  className={`${styles.formControl}  text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otp}
                />
              )}

              <Typography component="div" className="text-center">
                <ButtonRectangle action="Verify"></ButtonRectangle>
              </Typography>
            </form>
          )}
        </Formik>
      </Typography>

      <Typography component="div" className="text-center my-3">
        <Typography variant="div" className="text-center text-black-50">
          Didnt receive the OTP?{" "}
          <Link onClick={resendOtp} className="text-dark text-decoration-none">
            Resend OTP
          </Link>
        </Typography>
      </Typography>
    </Card>
  );
};

export default OTP;
