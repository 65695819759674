import * as React from 'react'
import { useEffect } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Link }  from 'react-router-dom'
import styles from '../../styles/SearchButton.module.css'
import { API_URL } from '../../utils/constant'

export default function UseAutocomplete() {
  const [inputValue, setInputValue] = React.useState('')
  const [suggestions, setSuggestions] = React.useState([])
  const [inputClicked, setInputClicked] = React.useState(false)

  async function fetchSuggestions() {
    if (!inputValue) return;

    const res = await fetch(`${API_URL}/school?keyword=` + inputValue)
    const data = await res.json()
    console.log(">>> Data --")
    setSuggestions(
      data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          location: item.location.district_name,
        }
      })
    )
  }

  useEffect(() => {


    if (inputValue.length > 2 && inputClicked) {
      fetchSuggestions()
    } else {
      setSuggestions([])
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, inputClicked])

  return (
    <div>
      <div
        style={{
          width: '100%',
        }}
      >
        <Formik
          initialValues={{
            search: '',
          }}
          validate={
            /* The validation schema. */
            yup.object({
              search: yup
                .string()
                .required('Required')
                .min(3, 'Too Short!')
                .max(50, 'Too Long!')
                .matches(
                  /^[a-zA-Z0-9 ]*$/,
                  'Only alphanumeric characters are allowed'
                )
                .trim(),
            })
          }
        >
          {({ handleSubmit, isSubmitting, handleChange }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                position: 'relative',
                display: 'inline-block',
               
               
              }}
            >
            
                      <input
                        className={styles.searchInput}
                        type='text'
                        name='search'
                        value={inputValue}
                        placeholder='Enter school or location to view results'
                        maxLength={50}
                        onChange={(e) =>
                          setInputValue(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          ) &&
                          setInputClicked(false) &&
                          handleChange(e)
                        }
                        onClick={() => setInputClicked(true)}
                      />
                      <Link
                        to={{
                          pathname: 'search-results/',
                          query: { keyword: inputValue },
                        }}
                        as={`search-results/${inputValue}`}
                      >
                        <button
                          className={styles.searchButton}
                          type='submit'
                          disabled={isSubmitting}
                          onClick={(e) => {
                            e.preventDefault()
                            window.location.href =
                              'search-results/' +
                              '?keyword=' +
                              inputValue
                          }}
                        >
                          <SearchOutlinedIcon />
                        </button>
                      </Link>
              
              {suggestions && suggestions.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    width: '100%',
                    height: `
                      ${suggestions.length > 5 ? '300px' : 'auto'}`,
                    overflow: 'auto',
                    zIndex: '100',
                    backgroundColor: 'white',
                    borderRadius: '0 0 25px 25px',
                  }}
                  className='shadow'
                >
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.value}
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                      }}
                      className='hoverColor'
                      onClick={() => {
                        /* Setting the input value to the suggestion label and then clearing the suggestions. */
                        setInputValue(suggestion.label)
                        setSuggestions([])
                      }}
                    >
                      {suggestion.label}, {suggestion.location}
                    </div>
                  ))}
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
