import React from 'react'
import { Link }  from 'react-router-dom'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import Card from '../../components/Authentication/CardLayout/Card'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Layout from '../../components/Layout/Layout'
import styles from '../../styles/Login.module.css'
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
      <AuthenticationPageLayout>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <br />
        <div className=' mt-5 pt-5'>
          <Card className={styles.loginContainerMargin} noWrap>
            <Link className=' text-decoration-none' to='/school-login'>
              <div className={styles.loginAs}>
                <div className={styles.loginAsText}>
                  <div className='d-flex'>
                    <div>
                      <AutoStoriesIcon
                        style={{
                          marginRight: '0.5rem',
                          fontSize: '3rem',
                        }}
                      />
                    </div>
                    <div className='mt-1' style={{ textAlign: "left"}}>
                      <div>I am a school staff</div>
                      <div
                        style={{
                          fontSize: '0.8rem',
                          color: '#C05179',
                          fontWeight: 300,
                        }}
                      >
                        I am a school staff and I want to login to my school
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link className=' text-decoration-none' to='/login'>
              <div className={styles.loginAs}>
                <div className={styles.loginAsText}>
                  <div className='d-flex'>
                    <div>
                      <SupervisorAccountIcon
                        style={{
                          marginRight: '0.5rem',
                          fontSize: '3rem',
                        }}
                      />
                    </div>
                    <div className='mt-1'  style={{ textAlign: "left"}}>
                      <div>I am a Parent</div>
                      <div
                        style={{
                          fontSize: '0.8rem',
                          color: '#C05179',
                          fontWeight: 300,
                        }}
                      >
                        I want to apply for a school for my child
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Card>
        </div>
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
