import React from 'react'
import { Link }  from 'react-router-dom'
import { Typography } from '@mui/material'
import { Formik } from 'formik'
import axios from 'axios'
import cookie from 'js-cookie'
import Card from '../CardLayout/Card'
import Feilds from '../Fields/InputField'
import CheckBox from '../Checkbox/Checkbox'
import Button from '../Button/Button'
import styles from '../../../styles/Login.module.css'
import { API_URL } from '../../../utils/constant'
import useAuth from '../../../utils/useAuth'
import jwtDecode from 'jwt-decode'

import {useNavigate} from 'react-router-dom';

const Login = () => {
  const [Iferrors, setIfErrors] = React.useState('')
  const { setToken } = useAuth()

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { email, password } = values
      const response = await axios
        .post(`${API_URL}` + `/user/login`, {
          userName: email,
          password,
        })
        .catch((err) => {
          console.log('Error ----', err)
          setIfErrors(err.response.data)
          setSubmitting(false)
          return;
        })



      console.log('Response----', response)

      if (!response) return;

      /* Getting the token from the response. */
      const token = response?.data
      const decoded = token ? jwtDecode(token) : null
      


      if (decoded && decoded.email_verified) {
        setToken(token)
        /* Setting the token in the local storage. */
        localStorage.setItem('token', token)
        /* Setting the token in the cookie. */
        cookie.set('token', token)
        /* Setting the token in the header of the axios request. */

         /* Setting the token in the header of the axios request. */
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // Redirect the user to the dashboard/home page.
         window.location.href = '/Dashboard';
       
        console.log("decoded:", decoded);
        
        // window.location.href = '/Dashboard'
      } else if (decoded && !decoded.email_verified){
        // Email is not verified, redirect the user to the OTP validation page.
        const data = {
        email: decoded.username,
        source: "email",
         };
         axios.get(`${API_URL}/user/otpResend`, { params: data })
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
        const otpValidationUrl = `/otp-validation?token=${encodeURIComponent(token)}`;
        window.location.href = otpValidationUrl;

      } else {
        setIfErrors('Invalid Credentials')
        // alert('Invalid Credentials')
        // window.location.href = '/login'
      }
    } catch (err) {
      setIfErrors(err.response.data.err)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <>
      <Card className={styles.loginContainerMargin} noWrap>
        <Typography variant='h5' className='mx-3 py-2' textAlign='center'>
          Sign in
        </Typography>
        <Formik
          /* Setting the initial values of the form. */
          initialValues={{ email: '', password: '' }}
          /* Validating the form. */
          validate={(values) => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Email is Required'
            }
            if (!values.password) {
              errors.password = 'Password is Required'
            }
            return errors
          }}
          /* A function that is called when the form is submitted. */
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {Iferrors ? (
                <div
                  className={styles.errorStyle}
                  role='alert'
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    backgroundColor: '#f8d7da',
                  }}
                >
                  {Iferrors}
                </div>
              ) : null}
              {errors.email && touched.email && errors.email ? (
                <>
                  <Feilds
                    label='Email'
                    placeholder={errors.email && touched.email && errors.email}
                    inputType='text'
                    name='email'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.email}
                    errors='email'
                    classNameProp={styles.errorStyle}
                  />
                </>
              ) : (
                <Feilds
                  label='Email'
                  placeholder='Enter your Email '
                  inputType='text'
                  name='email'
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                  errors='email'
                  classNameProp={styles.inputField}
                />
              )}

              {errors.password && touched.password && errors.password ? (
                <>
                  <Feilds
                    label='Password'
                    placeholder={
                      errors.password && touched.password && errors.password
                    }
                    name='password'
                    inputType='password'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    errors='password'
                    classNameProp={styles.errorStyle}
                  />
                </>
              ) : (
                <>
                  <Feilds
                    label='Password'
                    placeholder='Enter your password'
                    name='password'
                    inputType='password'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    errors='password'
                    classNameProp={styles.inputField}
                  />
                </>
              )}
              <CheckBox />
              <Button action='Sign in' />
              <Typography
                component='div'
                margin={1}
                textAlign={'end'}
                className='mx-5 text-black-50'
              >
                {"Don't have account?"}
                <Link to='/signup'>
                  <span className='mx-1 fw-semibold text-decoration-underline text-dark'>
                    Sign up
                  </span>
                </Link>
              </Typography>
            </form>
          )}
        </Formik>
      </Card>
    </>
  )
}

export default Login
