import React from 'react'
import { Typography } from '@mui/material'
import LayoutDashboard from '../../components/Dashboard/Layout'
import ApplyForm from '../../components/Dashboard/ApplyForm'

const Apply = () => {
  return (
    <Typography component='div'>
      <LayoutDashboard>
        <ApplyForm />
      </LayoutDashboard>
    </Typography>
  )
}

export default Apply
