import { useState } from 'react'
import cookie from 'js-cookie'
import jwt_decode from "jwt-decode";

const useAuth = () => {
  const [token, setToken] = useState(cookie.get('token'))
  const decoded = token ? jwt_decode(token) : null

  return { token, decoded, setToken }
}

export default useAuth
