import React from 'react'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Layout from '../../components/Layout/Layout'
import OTP from '../../components/Authentication/OTP/OTP'

const Index = () => {
  return (
    <Layout>
      <AuthenticationPageLayout>
        <br />
        <br />
        <br />
        <br />
        <br />
        <OTP />
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
