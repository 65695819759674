import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

import logo from '../../../components/images/logo.png'
import styles from '../../../styles/Header.module.css'
import HeaderDrawer from './HeaderDrawer'
import { pages, settings, login } from '../../../data/Home/HeaderItems'
import ProfileAvatar from './ProfileAvatar'

function ResponsiveHeader(props) {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const { window } = props

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      <Typography
        component={'div'}
        className={styles.mobileView}
        style={{
          zIndex: 10000,
        }}
      >
        <AppBar position='static'>
          <Container maxWidth='xl' className='bg-white'>
            <Toolbar disableGutters>
              <Typography
                variant='div'
                noWrap
                component='a'
                href='/'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <img
                  src={logo}
                  width={240}
                  priority
                  height={35}
                  className={styles.logo}
                  alt='logo'
                />
              </Typography>

              <Box
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                className='bg-white'
              >
                <IconButton
                  size='large'
                  aria-haspopup='true'
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <HeaderDrawer
                  container={container}
                  mobileOpen={mobileOpen}
                  handleDrawerToggle={handleDrawerToggle}
                  pages={pages}
                />
              </Box>
              <Typography
                variant='div'
                noWrap
                component='a'
                href=''
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                }}
                className='bg-white'
              >
                <img
                  src={logo}
                  width={200}
                  height={40}
                  className={styles.logo}
                  alt='logo'
                />
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <ProfileAvatar handleOpenUserMenu={handleOpenUserMenu} />
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {isUserLoggedIn
                    ? settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                          <Typography textAlign='center'>{setting}</Typography>
                        </MenuItem>
                      ))
                    : login.map((login) => (
                        <MenuItem key={login} onClick={handleCloseUserMenu}>
                          <Typography as='div' textAlign='center'>
                            {login}
                          </Typography>
                        </MenuItem>
                      ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Typography>
    </>
  )
}
export default ResponsiveHeader
