import React from 'react'
import styles from '../../../styles/Login.module.css'

const Button = (props) => {
  return (
    <>
      <button
        type='submit'
        value='submit'
        className={styles.buttonStyle}
        onClick={props.onSub}
      >
        {props.action}
      </button>
    </>
  )
}

export default Button
