import React from 'react'
import { Link }  from 'react-router-dom'
import axios from 'axios'
import { Typography } from '@mui/material'
import { Formik } from 'formik'
import Button from '../Button/Button'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import Card from '../CardLayout/Card'
import styles from '../../../styles/Login.module.css'
import { API_URL } from '../../../utils/constant'

const Signup = () => {

  


  const [Iferrors, setIfErrors] = React.useState('')
  const [showField, setShowField] = React.useState(true)
  const [email, setEmail] = React.useState('')
  const [mobile, setMobile] = React.useState('')

  let successMessage = false

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)

    // format the data object to send to the API
    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      password: values.password,
      role: values.userTypes,
    }
    axios
      .post(`${API_URL}/user/registration`, data)
      .then(() => {
        setEmail(values.email)
        setMobile(values.mobile)
        setSubmitting(false)
        successMessage = true
        setShowField(false)
        window.location = '/login'
        resetForm()
        console.log('success')
       
      
      })
      .catch((err) => {
        /* Setting the error message to the error message returned from the server. */
        
        setIfErrors(err.response.data)
        console.log(err.response)
        window.scrollTo(0, 0)
        setSubmitting(false)
      })
  }

  return (
    <>
      <Card className={styles.loginContainerMargin} noWrap>
        {showField ? (
          <>
            <Typography variant='h5' className='mx-3 py-3' textAlign='center'>
              Sign Up
            </Typography>
            <Formik
              initialValues={{
                name: '',
                email: '',
                mobile: '',
                password: '',
                confirmPassword: '',
                userTypes: '',
              }}
              validate={(values) => {
                const errors = {}

                // Name Validation
                if (!values.name) {
                  errors.name = 'Name is required'
                } else if (!/^[a-zA-Z ]+$/.test(values.name)) {
                  errors.name = 'Name must be only alphabets'
                }

                // Email Validation
                if (!values.email) {
                  errors.email = 'Email is required'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address'
                } else if (values.email.length > 50) {
                  errors.email = 'Email must be less than 50 characters'
                } else if (
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = 'Invalid email address'
                }

                //Mobile Validation
                if (!values.mobile) {
                  errors.mobile = 'Mobile Number is required'
                } else if (!/^[0-9]+$/.test(values.mobile)) {
                  errors.mobile = 'Mobile Number must be only numbers'
                } else if (values.mobile.length !== 10) {
                  errors.mobile = 'Mobile Number must be 10 digits'
                }

                //Passowrd Validation
                if (!values.password) {
                  errors.password = 'Password is required'
                } else if (values.password.length <= 7) {                 
                  errors.password = 'Password must be at least 8 characters'
                } else if (/^[0-9]+$/.test(values.password)) {
                  errors.password =
                    'Password must contain at least one alphabet'
                }

                //Confirm Passowrd Validation
                if (!values.confirmPassword) {
                  errors.confirmPassword = 'Confirm Password is required'
                } else if (values.password !== values.confirmPassword) {
                  errors.confirmPassword = 'Password does not match'
                }

                //User Type Validation
                if (!values.userTypes) {
                  errors.userTypes = 'Required'
                }
                return errors
              }}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>

        {successMessage ? (
                <div
                  role='alert'
                  style={{
                    color: '#57cd6b',
                    textAlign: 'center',
                    fontSize: '1rem',
                    padding: '10px',
                    marginBottom: '13px',
                    fontWeight: 'bold',
                  }}
                >
                 Account Created! Login here
                </div>
              ) : null}

                
            {Iferrors ? (
                    <div
                      className={styles.errorStyle}
                      role='alert'
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        backgroundColor: '#f8d7da',
                      }}
                    >
                      {Iferrors}
                    </div>
                  ) : null}

                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>Name</label>{' '}
                    <span className=' text-danger'>*</span>
                  </Typography>
                  {errors.name && touched.name && errors.name ? (
                    <>
                      <input
                        name='name'
                        type='text'
                        className={styles.errorStyle}
                        placeholder={errors.name && touched.name && errors.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        required
                      />
                      <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.name && touched.name && errors.name}
                      </Typography>
                    </>
                  ) : (
                    <input
                      name='name'
                      type='text'
                      className={styles.inputField}
                      placeholder='Enter your name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      required
                    />
                  )}
                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>Email Address</label>{' '}
                    <span className=' text-danger'>*</span>
                  </Typography>
                  {errors.email && touched.email && errors.email ? (
                    <>
                      <input
                        name='email'
                        type='email'
                        className={styles.errorStyle}
                        placeholder={
                          errors.email && touched.email && errors.email
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        required
                      />
                      <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.email && touched.email && errors.email}
                      </Typography>
                    </>
                  ) : (
                    <input
                      name='email'
                      type='email'
                      className={styles.inputField}
                      placeholder='Enter Email Address'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      required
                    />
                  )}
                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>Mobile Number</label>
                  </Typography>
                  {errors.mobile && touched.mobile && errors.mobile ? (
                    <>
                      <input
                        name='mobile'
                        type='text'
                        className={styles.errorStyle}
                        placeholder={
                          errors.mobile && touched.mobile && errors.mobile
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                        required
                      />
                      <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.mobile && touched.mobile && errors.mobile}
                      </Typography>
                    </>
                  ) : (
                    <input
                      name='mobile'
                      type='text'
                      className={styles.inputField}
                      placeholder='Enter mobile number'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                      required
                    />
                  )}


                    

                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>Password</label>{' '}
                    <span className=' text-danger'>*</span>
                  </Typography>
                  {errors.password && touched.password && errors.password ? (
                    <>
                      <input
                        name='password'
                        type='password'
                        className={styles.errorStyle}
                        placeholder={
                          errors.password && touched.password && errors.password
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        required
                      />

                      <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.password && touched.password && errors.password}
                      </Typography>
                    </>
                  ) : (
                    <input
                      name='password'
                      type='password'
                      className={styles.inputField}
                      placeholder='Enter Password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      required
                    />
                  )}
                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>
                      Confirm Password
                    </label>{' '}
                    <span className=' text-danger'>*</span>
                  </Typography>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <>
                      <input
                        name='confirmPassword'
                        type='password'
                        className={styles.errorStyle}
                        placeholder={
                          errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        required
                      />

                      <Typography
                        component='div'
                        textAlign={'end'}
                        className='mx-5 text-danger'
                      >
                        {errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword}
                      </Typography>
                    </>
                  ) : (
                    <input
                      name='confirmPassword'
                      type='password'
                      className={styles.inputField}
                      placeholder='Confirm Password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      required
                    />
                  )}
                  <Typography component='div' marginTop={2}>
                    <label className={styles.labelStyle}>
                      I am a <span className=' text-danger'>*</span>
                    </label>
                  </Typography>
                  <select
                    name='userTypes'
                    id=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userTypes}
                    className={styles.inputField}
                    required
                  >
                    <option value=''>Select</option>
                    <option value='parent'>Parent</option>
                    <option value='teacher'>Teacher</option>
                  </select>
                  {errors.userTypes && touched.userTypes && errors.userTypes ? (
                    <Typography
                      component='div'
                      textAlign={'end'}
                      className='mx-5 text-danger'
                    >
                      {errors.userTypes &&
                        touched.userTypes &&
                        errors.userTypes}
                    </Typography>
                  ) : null}
                  {Object.keys(errors).length === 0 &&
                  values.name !== '' &&
                  values.email !== '' &&
                  values.password !== '' &&
                  values.confirmPassword !== '' &&
                  values.userTypes !== '' ? (
                    <Typography
                      component='div'
                      marginTop={2}
                      className=' text-success'
                      textAlign={'center'}
                    >
                      All fields are valid and ready to submit!{' '}
                      <span className='text-success'>✔</span> <br />
                    </Typography>
                  ) : null}


                  <Button action='Sign Up' />
                  <Typography
                    component='div'
                    margin={1}
                    textAlign={'end'}
                    className='mx-5 text-black-50'
                  >
                    Already have an account?
                    <Link  to={`/login`}>
                      <span className='mx-1 fw-semibold text-decoration-underline text-dark'>
                        Sign In
                      </span>
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div className='text-center'>
            <DoneAllIcon
              className='text-success'
              style={{
                fontSize: 100,
                marginTop: 10,
                marginBottom: 20,
                color: '#4caf50',
              }}
            />
            <Typography component='div' className='text-success'>
              Your account has been created successfully!
            </Typography>
            
          </div>
        )}
      </Card>
    </>
  )
}

export default Signup

//  Author : @Bimasha Zaman
//  Date : 01-12-2022
//  Description : This is the signup page
//  Version : 1.0.0
//  Last Updated : 01-12-2022
