import React from 'react'
import LayoutDashboard from '../../components/Dashboard/Layout'
import ShortlistComponent from '../../components/Dashboard/Shortlist/ShortlistComponent'
import { Helmet } from 'react-helmet'

const Results = () => {
  return (
    <LayoutDashboard>
       <Helmet>
        <title>Shortlisted Schools</title>
      </Helmet>
      <ShortlistComponent />
    </LayoutDashboard>
  )
}

export default Results
