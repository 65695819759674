import { Link }  from 'react-router-dom'
import React, { useState } from 'react'
import styles from 'src/styles/Navbar.module.css'
import useAuth from '../../utils/useAuth'
import { style } from '@mui/system'

const Navbar = (props) => {
  const { token, decoded } = useAuth() || {}
  const [tokenStatus, setTokenStatus] = React.useState('')
  const [linkSet, setLinkSet] = React.useState('')


  const [isNavExpanded, setIsNavExpanded] = useState(false)
  
  /* Checking if the user is logged in or not. If the user is logged in, it will show the dashboard link.  */
  React.useEffect(() => {
    if (token) {
      // /* This is checking if the user is a school admin or not. If the user is a school admin, it will show the school dashboard link.*/
    
      // if (!decoded.email_verified) {
      //   setTokenStatus('')
      //   return
      // };
      if (decoded.role === 'school-admin') {
        setTokenStatus('Dashboard')
        setLinkSet('/school-dashboard/application-list')
      }  else {
        setTokenStatus('Dashboard')
        setLinkSet('/Dashboard')
      }
    } else {
      setTokenStatus('Login')
      setLinkSet('/login-as')
    }
  }, [token, decoded])

  return (
    <nav className={styles.Navbar} style={{ position: props.position }}>
      <div className={styles.logo}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.91)",
          }}
          className={styles.LogoStyle}
        >
          <span>Admission</span>
          <span className={styles.pedia}>Pedia</span>
        </Link>
      </div>

      <div
        className={`${styles.menuIcon} ${isNavExpanded ? styles.open : null}`}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={
          isNavExpanded ? styles.navbarElementsMenu : styles.navbarElements
        }
      >
        <ul>
          <li className={styles.navbarItem}>
            <Link className={styles.navbarItemStyle} to="/search-results/">
              Find Schools
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link className={styles.navbarItemStyle} to="/add-school">
              <span>For Schools</span>
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <a
              className={styles.navbarItemStyle}
              target="_blank"
              href="https://medium.com/@info_6783"
            >
              Blog
            </a>
          </li>
          {/* {console.log('token status----', tokenStatus, token, decoded)} */}

          {tokenStatus && (
            <li className={styles.navbarItemLast}>
              <Link className={styles.navbarItemStyle} to={linkSet}>
                {tokenStatus}
              </Link>
            </li>
          )}
        </ul>
      </div>

      {/* <div className={styles.navbarItem}>
                  <Link
                    className={styles.navbarItemStyle}
                    to="/search-results/"
                  >
                    Find Schools
                  </Link>
                </div>
                <div className={styles.navbarItem}>
                  <Link className={styles.navbarItemStyle} to="/add-school">
                    <span>For Schools</span>
                  </Link>
                </div>
                <div className={styles.navbarItem}>
                  <a
                    className={styles.navbarItemStyle}
                    target="_blank"
                    href="https://medium.com/@info_6783"
                  >
                    Blog
                  </a>
                </div>
               
                  
                    */}

      
     <Link className={styles.navbarItem} to={linkSet}>
        <button className={styles.SignInButton}>{tokenStatus}</button>
      </Link>
    </nav>
  );
}

export default Navbar
