import React from 'react'
import styles from 'src/styles/AddingSchool.module.css'
import 'src/styles/About.module.css'
import SchoolImg from "../../assets/school/schoolstudents.jpg"

const AboutUsComponent = () => {
  return (
    <>
      <div data-aos="fade-up" className='container'>
        <div className={`${styles.aboutUs} row mb-5`}>
          <div className='col-lg-6 col-md-12 col-sm-12 d-lg-flex'>
            <img
              src={SchoolImg}
              alt={'Add Your School'}
              width='724'
              height='592'
              className='img-fluid'
              style={{
                borderRadius: '5px',
               
              }}
            />
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 mb-lg-0 mb-5 txt-sm-center' >
            <div className={styles.Description}>
              <span className='fw-semibold'>Who We Are?</span>
            </div>
            <div className={styles.MoreDetails}>
              Established in 1999 with over 500 schools as clients across India.
              Current customers are school affiliated to different Boards like
              CBSE, ICSE, State Board, Matriculation, Anglo Indian and
              International School.
              <br />
              <br />
              <ul>
                <li> 15+ schools use SchoolsIndia ERP </li>
                <li> 50+ school websites designs </li>
                <li> 500+ school contests conducted</li>
                <li> 10M+ students participated</li>
                <li> 25,000+ Curated data for schools</li>
                <li> 6000+ followers on Schools India Linkedin page</li>
                <li>Media Coverage</li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default AboutUsComponent
