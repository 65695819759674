import React from 'react'
import styles from '../../../styles/Contact.module.css'

const ButtonRectangle = (props) => {
  return (
    <>
      <button
        type='submit'
        value='submit'
        className={styles.buttonRectangle}
       
      >
        {props.action}
      </button>
    </>
  )
}

export default ButtonRectangle
