import React from 'react'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Layout from '../../components/Layout/Layout'
import SchoolLogin from '../../components/Authentication/Login/SchoolLogin'
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
      <Helmet>
        <title>School Sign in</title>
      </Helmet>
      <AuthenticationPageLayout>
        <div className=' mt-5 pt-5'>
          <SchoolLogin />
        </div>
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
