import React from 'react'
import PaymentImg from 'src/assets/ForSchools/payment.svg'

const Payment = () => {
  return (
    <div
      className='container text-white'
      style={{
        fontFamily: 'Space Grotesk',
      }}
      data-aos="fade-up"
    >
      <div className='row'>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering'>
          <img
            src={PaymentImg}
            alt='Payment'
            width={500}
            height={500}
            className='img-fluid'
          />
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5'>
          <h1 className='fs-1 pb-4'>
            Admission & Application Fees
            <span
              className='fw-bold fs-1'
              style={{
                color: '#864D61',
                borderBottom: '4px solid #864D61',
              }}
            >
              {' '}
              Payment Online
            </span>
          </h1>
          <div className='fs-3'>
            Easily process application and admission fees online using multiple
            payment gateways.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
