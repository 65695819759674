import React from 'react'

const Loading = () => {
  return (
    <div className='text-center centering'>
      <div
        className='spinner-border'
        role='status'
        style={{
          width: '3rem',
          height: '3rem',
          color: '#F67DAA',
          marginTop: '5rem',
        }}
      >
        <span className='sr-only'></span>
      </div>
    </div>
  )
}

export default Loading
