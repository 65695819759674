import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'

import AboutUs from "./pages/about-us/index";
import AddSchool from "./pages/add-school/index";
import HomeComponent from 'src/components/Home/HomeComponent'
import Apply from 'src/pages/apply/[id]';
import ApplyThankYou from 'src/pages/apply/thank-you';
import Contact from 'src/pages/contact/';
import Dashboard from 'src/pages/Dashboard/';
import DashboardApply from 'src/pages/Dashboard/apply';
import DashboardAppliedSchools from 'src/pages/Dashboard/applied-schools';
import MyProfile from 'src/pages/Dashboard/my-profile';
import ShortlistedSchools from 'src/pages/Dashboard/sortlisted-schools';
import DashboardLogout from 'src/pages/Dashboard/logout';
import DashboardTrackApplication from 'src/pages/Dashboard/track-application';
import FAQS from "src/pages/faqs";
import ForgetPassword from "src/pages/forget-password";
import FreeDemo from "src/pages/free-demo";
import GetStarted from "src/pages/get-started";
import GetStartedAddSchool from "src/pages/get-started/add-school";
import GetStartedCompleted from "src/pages/get-started/completed";
import GetStartedConfirmDetails from "src/pages/get-started/confirm-details";
import GetStartedSchoolInfo from "src/pages/get-started/school-info";
import GetStartedAdditionInfo from "src/pages/get-started/additional-info/[id]";
import Login from "src/pages/login";
import LoginAs from "src/pages/login-as";
import Notification from "src/pages/notification";
import OtpValidation from "src/pages/otp-validation";
import PasswordReset from "src/pages/password-reset";
import PrivacyPolicy from "src/pages/privacy-policy";

import SchoolDashboard from "src/pages/school-dashboard";
import SchoolDashboardLogout from "src/pages/school-dashboard/logout";
import SchoolDashboardEdit from "src/pages/school-dashboard/edit/class";
import SchoolDashboardConfig from "src/pages/school-dashboard/configuration";
import SchoolDashboardSettings from "src/pages/school-dashboard/settings";
import SchoolDashboardApplicationList from "src/pages/school-dashboard/application-list";
import SchoolDashboardApplicationClass from "src/pages/school-dashboard/application-list/class";
import SchoolDashboardApplicationListDetails from "src/pages/school-dashboard/application-list/details";

import SchoolLogin from "src/pages/school-login";
import SearchResults from "src/pages/search-results";
import Signup from "src/pages/signup";
import TermsOfUse from "src/pages/terms-of-use";

import ScrollToTop from "./components/Layout/ScrollToTop";
import useAuth from "./utils/useAuth";

import "src/styles/globals.css"

function App() {

  const isLoggedIn = localStorage.getItem("token")
  const { decoded } = useAuth() || {}

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Switch>
          <Route exact path={"/about-us"} render={(props) => <AboutUs />} />
          <Route exact path={"/add-school"} render={(props) => <AddSchool />} />
          <Route exact path={"/apply/thank-you"} render={(props) => <ApplyThankYou />} />
          <Route exact path={"/apply/:id"} render={(props) => {if(isLoggedIn && decoded.email_verified){ return<Apply /> }else { return <Login/>}}} />
          <Route exact path={"/contact"} render={(props) => <Contact />} />
          <Route exact path={"/dashboard/applied-schools"} render={(props) => <DashboardAppliedSchools />} />
          <Route exact path={"/dashboard/apply"} render={(props) => <DashboardApply />} />
          <Route exact path={"/dashboard/my-profile"} render={(props) => <MyProfile />} />
          <Route exact path={"/dashboard/shortlisted-schools"} render={(props) => <ShortlistedSchools />} />
          <Route exact path={"/dashboard/logout"} render={(props) => <DashboardLogout />} />
          <Route exact path={"/dashboard/track-application"} render={(props) => <DashboardTrackApplication />} />
          <Route exact path={"/dashboard"} render={(props) => <Dashboard />} />
          <Route exact path={"/faqs"} render={(props) => <FAQS />} />
          <Route exact path={"/forget-password"} render={(props) => <ForgetPassword />} />
          <Route exact path={"/free-demo"} render={(props) => <FreeDemo />} />
          <Route exact path={"/get-started/add-school"} render={(props) => <GetStartedAddSchool />} />
          <Route exact path={"/get-started/completed"} render={(props) => <GetStartedCompleted />} />
          <Route exact path={"/get-started/confirm-details"} render={(props) => <GetStartedConfirmDetails />} />
          <Route exact path={"/get-started/school-info"} render={(props) => <GetStartedSchoolInfo />} />
          <Route exact path={"/get-started/additional-info/:id"} render={(props) => <GetStartedAdditionInfo />} />

          <Route exact path={"/get-started"} render={(props) => <GetStarted />} />
          <Route exact path={"/login"} render={(props) => {if(!isLoggedIn || decoded.role ==='school-admin'){ return <Login />} else { return <HomeComponent/>}}} />
          <Route exact path={"/login-as"} render={(props) =>{if(!isLoggedIn){ return <LoginAs />} else { return <HomeComponent/>}}}  />

          <Route exact path={"/notification"} render={(props) => <Notification />} />
          <Route exact path={"/otp-validation"} render={(props) =>  <OtpValidation />} />
          <Route exact path={"/password-reset"} render={(props) => {if(!isLoggedIn){ return <PasswordReset />} else { return <HomeComponent/>}}} />
          <Route exact path={"/privacy-policy"} render={(props) => <PrivacyPolicy />} />

          <Route exact path={"/school-dashboard/configuration"} render={(props) => <SchoolDashboardConfig />} />
          <Route exact path={"/school-dashboard/settings"} render={(props) => <SchoolDashboardSettings />} />
          <Route exact path={"/school-dashboard/application-list/class"} render={(props) => <SchoolDashboardApplicationClass />} />
          <Route exact path={"/school-dashboard/application-list/details"} render={(props) => <SchoolDashboardApplicationListDetails />} />
          <Route exact path={"/school-dashboard/application-list/"} render={(props) => <SchoolDashboardApplicationList />} />
          <Route exact path={"/school-dashboard/edit"} render={(props) => <SchoolDashboardEdit />} />
          <Route exact path={"/school-dashboard/logout"} render={(props) => <SchoolDashboardLogout />} />

          <Route exact path={"/school-dashboard"} render={(props) => <SchoolDashboard />} />
          <Route exact path={"/school-login"} render={(props) => <SchoolLogin />} />
          <Route exact path={"/search-results"} render={(props) => <SearchResults />} />
          <Route exact path={"/signup"} render={(props) => {if(!isLoggedIn){ return <Signup />} else { return <HomeComponent/>}}} />
          <Route exact path={"/terms-of-use"} render={(props) => <TermsOfUse />} />

          <Route exact path={"/"} render={(props) => <HomeComponent />} />

        </Switch>
       
      </BrowserRouter>
    </div>
  );
}

export default App;
