import React from 'react'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Layout from '../../components/Layout/Layout'
import Login from '../../components/Authentication/Login/Login'
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
       <Helmet>
        <title>Login</title>
      </Helmet>
      <AuthenticationPageLayout>
        <div className=' mt-5 pt-5'  style={{ textAlign: "left"}}>
          <Login />
        </div>
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
