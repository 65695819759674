import * as React from 'react'
import { Link }  from 'react-router-dom'

export default function CheckBox() {
  return (
    <div>
      <Link
        to='/forget-password'
        style={{
          textDecoration: 'none',
        }}
      >
        <div className=' mx-lg-5 mx-4 text-muted text-end'>
          Forget Password?
        </div>
      </Link>
    </div>
  )
}
