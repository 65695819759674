import React from 'react'
import Filter from '../../Filter/Filter'
import styles from '../../../styles/SearchButton.module.css'
import { useMediaQuery } from '@mui/material'

const SideBar = () => {
  const isSmallScreen = useMediaQuery('(max-width:900px)')
  let displayNone
  if (isSmallScreen) {
      displayNone = 'd-none'
   } else {
      displayNone = ''
   }


  return (
    <div className={styles.filterWrapper}>
      <div
      className={`px-4 d-lg-block ${displayNone}`} 
      style={{
        
       
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '15px',
        boxShadow: '-1px 0px 20px -4px rgba(153, 153, 153, 0.25)',
       
      }}
    >
      <div className='pt-4'>
        <h3>Filters</h3>
      </div>
      <Filter />
    </div>
    </div>
    
  )
}

export default SideBar
