import React from 'react'
import StatusTrackingImg from 'src/assets/ForSchools/status-tracking.svg'

const StatusTracking = () => {
  return (
    <div
      className='container py-lg-5'
      style={{
        fontFamily: 'Space Grotesk',
      }}
      data-aos="fade-up"
    >
      <div className='row my-5 py-lg-5'>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering d-flex d-lg-none'>
          <img
            src={StatusTrackingImg}
            alt='StatusTracking'
            width={500}
            height={500}
            className='img-fluid'
          />
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5 text-white'>
          <h1 className='fs-1 pb-4'>
            Application Status{' '}
            <span
              className='fw-bold fs-1'
              style={{
                color: '#864D61',
                borderBottom: '4px solid #864D61',
              }}
            >
              {' '}
              Tracking{' '}
            </span>
          </h1>
          <div className=' text-white fs-3'>
            AdmissionPedia allows schools to Track, sort, and filter
            applications which saves time and helps schools to stay organized.
          </div>
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering d-none d-lg-flex'>
          <img
            src={StatusTrackingImg}
            alt='StatusTracking'
            width={500}
            height={500}
            className='img-fluid'
          />
        </div>
      </div>
    </div>
  )
}

export default StatusTracking
