import React from 'react'
import { Link }  from 'react-router-dom'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import Layout from '../../components/Layout/Layout'

const completed = () => {
  return (
    <Layout>
      <div
        style={{
          height: '100vh',
          color: '#000',
          backgroundColor: '#FEF7F9',
        }}
        className='centering mt-5 pt-5 align-content-center justify-content-center text-center'
      >
        <TaskAltIcon
          style={{
            fontSize: '10rem',
            color: '#F876A7',
            marginTop: '5rem',
          }}
        />
        <h3>Application Submitted</h3>
        <br />
        <div>
          Your application has been submitted successfully. Keep an eye on your
          email for updates.
        </div>
        <div>
          <Link
            to='/Dashboard/applied-schools'
            style={{
              color: '#F876A7',
              textDecoration: 'underline',
            }}
          >
            Click here to view the application
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default completed
