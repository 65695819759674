import { Link }  from 'react-router-dom'
import React from 'react'
import AddYourSchoolIndexPageInfo from '../../data/Home/AddingSchoolInfoIndexPage'
import styles from '../../styles/AddingSchool.module.css'
import SchoolImg from "../../assets/school/school.jpg"
import 'src/styles/AddingSchool.module.css'


const AddYourSchoolComponent = () => {


  return (
    <div data-aos="fade-up" className='container my-lg-2 my-0 py-lg-1 py-0'>
      <div className='row my-5 py-5'>
        <div className='col-lg-6 col-md-12 col-sm-12 mb-lg-0 mb-5 txt-sm-center'  >
          {AddYourSchoolIndexPageInfo.map((info) => (
            <div key={info.id + info.title}>
              <div className={styles.Description}>
                <span className='fw-semibold'>{info.title}</span>
              </div>
              <div className={styles.MoreDetails}>
                {info.description}
                <span>
                  <Link
                    to='add-school'
                    style={{
                      color: '#F67DAA',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Learn more
                  </Link>
                </span>
              </div>
            </div>
          ))}
          <div className='mt-4 d-flex '>
            <Link to='/get-started' className={`${styles.button} txt-sm-center`}>
             Get Started
            </Link>
          </div>
        </div>
        <div className=' col-lg-6 col-md-12 col-sm-12'>
          <img
            src={SchoolImg}
            alt={'Add Your School'}
            width='750'
            height='600'
            className='img-fluid'
            style={{
              borderRadius: '5px',
          
              backgroundColor: 'transparent',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AddYourSchoolComponent
