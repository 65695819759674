export default function getRandomString(id, length, chars) {
  var result = ''
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)]
  return id + result
}

export const randomGenerationString =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const RE_DIGIT = new RegExp(/^\d+$/)
