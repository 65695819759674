import React from 'react'
import { Container } from '@mui/material'
import { Formik } from 'formik'
import { useRouter } from '../../../hooks/useRouter'
import { API_URL } from '../../../utils/constant'
import PageLayout from '../../../components/GetStarted/Layout/PageLayout'
import FormHead from '../../../components/GetStarted/Form/FormHead'
import FormLayout from '../../../components/GetStarted/Layout/FormLayout'
import styles from '../../../styles/GetStarted.module.css'

const AdditionalInfo = () => {
  /* A hook that allows us to access the router object. */
  const router = useRouter()
  const schoolId = router.query.id
  const [resErr, setResErr] = React.useState('')
  return (
    <div>
      <PageLayout>
        <FormHead itemTitle='Few more details before we can onboard you' />
        <FormLayout>
          <Formik
            initialValues={{
              name: '',
              schoolEmail: '',
              schoolPhone: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                name: values.name,
                email: values.schoolEmail,
                phoneNumber: values.schoolPhone,
                schoolId: schoolId,
              }
              fetch(`${API_URL}/school/selfSignupSchoolAdmin`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              })
                .then((res) => res.json())
                .then((res) => {
                  console.log(res)
                  if (!res.success) {
                    setResErr(res.err || res.message)
                    window.scrollTo(0, 0);
                  } else {
                    console.log(res)
                    router.push('/get-started/completed')
                  }
                })
                .catch((err) => {
                  console.error(err)
                })
              setSubmitting(false)
            }}
          >
            {({ errors, values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {resErr.length > 0 ? (
                  <div className='text-center bg-danger p-2 rounded text-white mb-4'>
                    {resErr}
                  </div>
                ) : null}
                <Container maxWidth='md' style={{ color: 'white' }}>
                  <div className='my-4'>
                    <div className=' text-start'>
                      <label htmlFor=''>Name</label>
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Your Name'
                        className={styles.field}
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                      />

                      {errors.name && (
                        <div className=' text-end text-danger'>
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className=' text-start'>
                      <label htmlFor=''>Email</label>
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Your Email'
                        className={styles.field}
                        name='schoolEmail'
                        value={values.schoolEmail}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className=' text-end text-danger'>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className=' text-start'>
                      <label htmlFor=''>Phone Number</label>
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Phone Number'
                        className={styles.field}
                        name='schoolPhone'
                        value={values.schoolPhone}
                        onChange={handleChange}
                      />
                      {errors.schoolPhone && (
                        <div className=' text-end text-danger'>
                          {errors.schoolPhone}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='my-5'>
                    <button
                      style={{
                        backgroundColor: '#F876A7',
                        width: '100%',
                        padding: '15px',
                        border: 'none',
                      }}
                      type='submit'
                    >
                      Confirm
                    </button>
                  </div>
                </Container>
              </form>
            )}
          </Formik>
        </FormLayout>
      </PageLayout>
    </div>
  )
}

export default AdditionalInfo
