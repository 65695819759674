import React from 'react'

const ApplyForm = () => {
  return (
    <div className='container'>
      <div className='row mt-5 pt-5'>
        <div className='col-md-12 mt-5 pt-5'>
          <div className=' bg-white p-5 shadow rounded-4 w-75'>
            <div className='card-body'>
              <h3 className='card-title'>Application Form</h3>
              <form>
                <div className='row'>
                  <div className='col-md-12 col-lg-12'>
                    <div className='form-group'>
                      <label htmlFor='name'>Name</label>
                      <input
                        type='text'
                        className='form-control'
                        id='name'
                        placeholder='Enter your name'
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyForm
