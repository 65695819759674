import React from 'react'
import HeroSection from 'src/components/ForSchool/HeroSection'
import LongQues from 'src/components/ForSchool/Features/LongQues'
import StatusTracking from 'src/components/ForSchool/Features/StatusTracking'
import InterviewSchedule from 'src/components/ForSchool/Features/InterviewSchedule'
import Communication from 'src/components/ForSchool/Features/Communication'
import Payment from 'src/components/ForSchool/Features/Payment'
import Header from 'src/components/ForSchool/Partials/Header'
import PartnerSchools from 'src/components/ForSchool/PartnerSchools'
import Footer from 'src/components/ForSchool/Partials/Footer'
import ChatwootWidget from 'src/components/ChatwootWidget/ChatwootWidget'
import ContactSection from 'src/components/ForSchool/Features/Contact'
import {Helmet} from "react-helmet";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";



const AddShcool = () => {
  useEffect(() => {
    Aos.init({duration: 1500})
}, [])

  return (
    <>
     <Helmet>
          <title>Add your school</title>
          <meta name='description' content='AdmissionPedia' />
          <link rel='icon' href='/favicon.ico' />
      </Helmet>
      <Header />
      <HeroSection />
      <PartnerSchools />
      <div
        style={{
          backgroundColor: '#212121',
        }}
      >
        <LongQues />
        <StatusTracking />
        <InterviewSchedule />
        <Communication />
        <Payment />
        <ContactSection/>
        <Footer />
        
        <ChatwootWidget />
      </div>
    </>
  )
}

export default AddShcool
