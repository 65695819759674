import React from 'react'
import PageLayout from '../../components/GetStarted/Layout/PageLayout'
import Image from 'src/assets/confirmation/icon.png'
const completed = () => {
  return (
    <PageLayout>
      <div
        style={{
          height: '70vh',
         
        }}
       
      >
        <div style={{
           color: 'white',
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
           textAlign: 'center'
        
        }}>
        <img src={Image} alt='' height={120} width={140} />
        <h3>School Added</h3>
        <br />
        <div>Thank you for choosing AdmissionPedia</div>
        <div>We sent you a confirmation email</div>
        </div>
       
      </div>
    </PageLayout>
  )
}

export default completed
