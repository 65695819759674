import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import MenuIcon from '@mui/icons-material/Menu'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { useRouter } from '../../../hooks/useRouter'
import { useMediaQuery } from '@mui/material'






export default function SpeedDialTooltipOpen(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const router = useRouter()

  const { actions } = props;

  const isSmallScreen = useMediaQuery('(max-width:900px)');

  if (!isSmallScreen) {
    // if the screen is not small, return null to not render the component
    return null;
  }

  return (
    <Box
      sx={{
        transform: 'translateZ(0px)',
        flexGrow: 1,
        position: 'fixed',
        bottom: 16,
        left: 16,
        zIndex: 10999,
      }}
    >
      <SpeedDial
        ariaLabel='Dashboard'
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          zIndex: 9999,
          
        }}
        icon={<MenuIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          sx: {
            bgcolor: '#F87FAA',
            '&:hover': {
              bgcolor: '#FCC4D4',
            }
          }
        }}
        
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            
            // tooltipOpen
            onClick={(e) => {
              e.preventDefault()
              router.push(action.link)
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}
