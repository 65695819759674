import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import cookie from 'js-cookie'
import styles from '../../../styles/SearchResults.module.css'
import Sidebar from '../Partials/Sidebar'
import MainLayout from './MainLayout'
import SchoolLogin from '../../Authentication/Login/SchoolLogin'
import SpeedDialTooltipOpen from 'src/components/Dashboard/NavigateDial/SpeedNavigate'
import ArticleIcon from '@mui/icons-material/Article'
import LogoutIcon from '@mui/icons-material/Logout'
import TuneIcon from '@mui/icons-material/Tune'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import { useMediaQuery } from '@mui/material'
import { Helmet } from 'react-helmet'

const PageLayout = ({ children }) => {
  const token = cookie.get('token')

  const [isLogged, setIsLogged] = React.useState(false)

  const isSmallScreen = useMediaQuery('(max-width:900px)');
  let displayNone
  if (isSmallScreen) {
      displayNone = 'd-none'
  } else {
     displayNone = ''
   }
  

  const actions = [
    {
      name: 'Logout',
      link: '/school-dashboard/logout',
      icon: <LogoutIcon />,
    },
   
    
    {
      name: 'Change Password',
      link: '/school-dashboard/settings',
      icon: <TuneIcon />,
    },

    {
      name: 'Admission Settings',
      link: '/school-dashboard/configuration',
      icon: <RoomPreferencesIcon />,
    },
    {
      name: 'Application List',
      link: '/school-dashboard/application-list',
      icon: <ArticleIcon />,
    },
   
  ]

  /* Checking if the token is present in the cookie. If it is, it sets the isLogged state to true. If it
is not, it sets the isLogged state to false. */
  React.useEffect(() => {
    if (token) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }, [token])

  return (
    <Typography component='div'>
        <Helmet>
         <title>School Dashboard</title>
        </Helmet>
      {isLogged ? (
        <MainLayout>
          <Typography component='div' className={styles.mainContainer}>
            <Container maxWidth='xl'>
              <Grid container direction='row'>
                <Grid item lg={2.5} md={4} sm className={`d-lg-flex ${displayNone}`}>
                  <Sidebar />
                </Grid>
                <Grid item lg={9.5} md={8} sm={12} xs={12}>
                  <main>{children}</main>
                </Grid>
              </Grid>
            </Container>
          </Typography>
          <SpeedDialTooltipOpen actions= {actions} />
        </MainLayout>
      ) : (
        <SchoolLogin />
      )}
    </Typography>
  )
}

export default PageLayout
