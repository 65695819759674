import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import styles from '../../../styles/Login.module.css'

const AuthenticationPageLayout = ({ children }) => {
  return (
    <Typography component='div' className={styles.loginContainer}>
      <Container maxWidth='sm'>
        
          <Grid item xs md sm lg={2}>
            <main>{children}</main>
          </Grid>
          {/* <Grid item xs md sm lg={8} className='d-lg-block d-none'>
            <Typography component='div' className='w-auto'>
              <img
                src='/LoginLayoutImage/signin.png'
                width={700}
                height={550}
                alt='Admission Pedia Login'
                priority
              />
            </Typography>
          </Grid> */}
       
      </Container>
    </Typography>
  )
}

export default AuthenticationPageLayout
