import { useState, useEffect, useContext } from 'react'
import { Formik } from 'formik'
import { useRouter } from '../../hooks/useRouter';
import axios from 'axios'
import { API_URL } from '../../utils/constant'
import styles from '../../styles/SearchFilter.module.css'
import { fontSize } from '@mui/system'
import { FilterContext } from '../../context/FilterContext';

function Filter(props) {

  const { filters, setFilters } = useContext(FilterContext);

  const router = useRouter()
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState('')
  const [location, setLocation] = useState('')
  const [board, setBoard] = useState('')
  const [classs, setClasss] = useState('')
  const [admissionStatus, setAdmissionStatus] = useState('')
  const [gender, setGender] = useState('')
  const [dayBoarding, setDayBoarding] = useState('')

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(`${API_URL}/school/search-filters`)
        setBoard(res.data.boards)
        setClasss(res.data.classes)
        setGender(res.data.genders)
        setAdmissionStatus(res.data.applicationStatuses)
        setDayBoarding(res.data.dayBoarding)
      } catch (err) {
        console.error(err)
      }
    }
    fetchFilters()
  }, [])

  useEffect(() => {
    async function fetchSuggestions() {
      const res = await fetch(`${API_URL}/location?keyword=${inputValue}`)
      const data = await res.json()
      setSuggestions(
        data.map((item) => {
          return {
            value: item.district_id,
            label: item.district_name,
          }
        })
      )
    }

    /* Checking if the inputValue is greater than 0, if it is then it will fetch the suggestions, if not
    then it will set the suggestions to an empty array. */
    if (inputValue.length > 0) {
      fetchSuggestions()
    } else {
      setSuggestions([])
    }
  }, [inputValue])

  return (
    <>
      <div className='bg-white px-4 py-2'>
        <Formik
          initialValues={{
            locations: '',
            boards: '',
            classs: [],
            admissionStatus: '',
            genders: '',
            dayBoarding: '',
          }}
          /* Setting the query parameters. */
          onSubmit={async (values) => {


            const boardValue =  values.boards
            const newClasses = values.classes?.length ? await Promise.all(values.classes.map((classId) => +classId)) : null
            
            setFilters({
              ...filters,
              district: location ? location : null,
              board: boardValue ? boardValue : null,
              class: newClasses,
              admissionStatus: values.admissionStatus && !isNaN(values.admissionStatus) ? values.admissionStatus : null,
              gender: values.genders ? values.genders : null,
              residencyType: values.dayBoarding ? values.dayBoarding : null
            })
            // const query = new URLSearchParams()
            // Object.entries(values).forEach(([key, value]) => {
            //   if (value !== null && value !== undefined) {
            //     query.set(key, value)
            //   }
            // })

            // router.push({
            //   pathname: router.pathname,
            //   query: {
            //     keyword: router.query.keyword,
            //     location: inputValue,
            //     board: values.boards,
            //     classs: values.classs,
            //     gender: values.genders,
            //     admissionStatus: values.admissionStatus,
            //     dayBoarding: values.dayBoarding,
            //   },
            // })
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <label className={styles.filterLabel}>Location</label>
                </div>
                <div
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <input
                    type='text'
                    onChange={(e) => {
                      setInputValue(e.target.value)
                      handleChange(e)
                      setLocation(e.target.value)
                    }}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '0.6px solid #ccc',
                      outline: 'none',
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      color: '#000',
                      padding: '0 10px',
                    }}
                    placeholder='Location'
                    name='location'
                    onBlur={handleBlur}
                    value={inputValue || values.locations}
                  />
                  {suggestions && suggestions.length > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '180px',
                        overflow: 'auto',
                        boxShadow: '0 0 10px #ccc',
                      }}
                    >
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index + 1}
                          // style={{
                          //   padding: '10px',
                          //   cursor: 'pointer',
                          //   ':hover': {
                          //     backgroundColor: 'yellow !important'
                          //   }
                          // }}
                          className="item-row-suggestion"
                          value={suggestion.name}
                          onClick={() => {
                            /* This is setting the inputValue to the label of the suggestion, setting
                            the location to the name of the suggestion and setting the suggestions
                            to an empty array. */

                            setInputValue(suggestion.label)
                            setLocation(suggestion.value)
                            setSuggestions([])
                          }}
                        >
                          {suggestion.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Board</label>
                </div>
                <div>
                  <select
                    name='boards'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.boards}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '0.6px solid #ccc',
                      outline: 'none',
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      color: '#000',
                      padding: '0 10px',
                    }}
                  >
                    <option value=''>Select</option>
                    {board &&
                      board.map((item) => (
                        <option value={item.id} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

                       
              <div className={styles.filterClass}>

              <div className='line'>
                      <hr></hr>
                      </div>

                <div style={{padding: '10px 0',}}>
                  <label className={styles.filterLabel}  style={{margin: 0}}>Class</label>
                </div>
                <div
                  style={{
                    height: '230px',
                    backgroundColor: '#fff',
                    width: '100%',
                    outline: 'none',
                    fontSize: '16px',
                    backgroundColor: '#fff',
                    overflow: 'auto',
                    marginBottom: '20px',
                  }}
                >
                  {classs &&
                    classs.map((item) => (
                      <div
                        key={item.id + item.name + 1}
                        style={{
                          padding: '5px',
                          cursor: 'pointer',
                          display: 'flex',
                        }}
                        value={item.name}
                      >
                        <input
                          type='checkbox'
                          name='classes'
                          value={item.id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            color: '#f77eaa',
                    
                          }}
                          htmlFor={item.id}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          id={item.id}
                          onClick={() => {
                            document.getElementById(
                              item.id
                            ).previousSibling.checked =
                              !document.getElementById(item.id).previousSibling
                                .checked // This is to toggle the checkbox
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    ))}
                </div>
                <div className='line'>
                      <hr></hr>
                      </div>
              </div>

              <div>
                <div>
                  <label className={styles.filterLabel}>Admission Status</label>
                </div>
                <div>
                  <select
                    name='admissionStatus'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '0.6px solid #ccc',
                      outline: 'none',
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      color: '#000',
                      padding: '0 10px',
                    }}
                  >
                    <option value=''>Select</option>
                    {admissionStatus &&
                      admissionStatus.map((item) => (
                        <option
                          value={item.value}
                          key={item.id + item.name + 1}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Gender</label>
                </div>
                <div>
                  <select
                    name='genders'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '0.6px solid #ccc',
                      outline: 'none',
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      color: '#000',
                      padding: '0 10px',
                    }}
                  >
                    <option value=''>Select</option>
                    {gender &&
                      gender.map((item) => (
                        <option value={item.name} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Day Boarding</label>
                </div>
                <div>
                  <select
                    name='dayBoarding'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      width: '100%',
                      height: '40px',
                      border: '0.6px solid #ccc',
                      outline: 'none',
                      fontSize: '16px',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      color: '#000',                   
                      padding: '0 10px',
                    }}
                  >
                    <option value=''>Select</option>
                    {dayBoarding &&
                      dayBoarding.map((item) => (
                        <option value={item.name} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <button
                 onClick={() => {
                  props.handleClose()
                }}
                  type='submit'
                  style={{
                    width: '100%',
                    height: '40px',
                    border: 'none',
                    borderRadius: '10px',
                    margin: '2rem 0',
                    cursor: 'pointer',
                    backgroundColor: '#F77EAA',
                    boxShadow: '0 0 10px #ccc',
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default Filter