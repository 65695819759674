import React from 'react'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Signup from '../../components/Authentication/Signup/Signup'
import Layout from '../../components/Layout/Layout'
import { Helmet } from 'react-helmet'

const Index = () => {
  return (
    <Layout>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <AuthenticationPageLayout>
        <div className='mt-5 pt-5'>
          <Signup />
        </div>
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
