import React from 'react'
import PageLayout from '../../components/GetStarted/Layout/PageLayout'

const ContactUs = () => {
  return (
    <PageLayout>
      <div
        className='container-fluid py-lg-5'
        style={{
          fontFamily: 'Space Grotesk',
          height: '100vh',
        }}
        data-scroll-to='contact'
      >
        <div className='row my-5 py-lg-5 border border-white'>
          <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5 px-lg-5 px-3'>
            <h1 className='fs-1 pb-4 text-white'>
              Request a free demo of our platform
            </h1>
            <div className='text-white fs-3 text-white'>
              Feel free to write to us directly info@admissionpedia.com
            </div>
            <br />
            <div className='text-white fs-3 text-white'>or.</div>
            <br />
            <div className='text-white fs-3 text-white'>
              AdmissionPedia Pvt. Ltd No. 240, 6th St, Palkalai Nagar,
              Palavakkam, Chennai, Tamil Nadu 600041, India
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default ContactUs
