import React from 'react'
import { Typography } from '@mui/material'
import { Formik } from 'formik'
import axios from 'axios'
import cookie from 'js-cookie'
import Card from '../CardLayout/Card'
import Feilds from '../Fields/InputField'
import Button from '../Button/Button'
import styles from '../../../styles/Login.module.css'
import { API_URL } from '../../../utils/constant'

const SchoolLogin = () => {
  const [Iferrors, setIfErrors] = React.useState('')

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { email, password } = values
      const response = await axios
        .post(`${API_URL}` + `/user/school-login`, {
          userName: email,
          password,
        })
        .catch((err) => {
          setIfErrors(err.response.data.message)
          console.log(err)
        })

      /* Getting the token from the response. */
      const token = response.data

      if (token) {
        /* Setting the token in the local storage. */
        localStorage.setItem('token', token.token)
        /* Setting the token in the cookie. */
        cookie.set('token', token.token)
        /* Setting the token in the header of the axios request. */
        axios.defaults.headers.common['Authorization'] = `Bearer ${token.token}`
        // redirect the user to the dashboard or home page
        window.location.href = '/school-dashboard/application-list'
      } else {
        setIfErrors('Invalid Credentials')
        alert('Invalid Credentials')
        window.location.href = '/school-login'
      }
    } catch (err) {
      setIfErrors(err.response.data.err)
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <>
      <Card className={styles.loginContainerMargin} noWrap>
        <Typography variant='h5' className='mx-3 py-2' textAlign='center'>
          School Sign in
        </Typography>
        <Formik
          /* Setting the initial values of the form. */
          initialValues={{ email: '', password: '' }}
          /* Validating the form. */
          validate={(values) => {
            const errors = {}
            if (!values.email) {
              errors.email = 'Email is Required'
            }
            if (!values.password) {
              errors.password = 'Password is Required'
            }
            return errors
          }}
          /* A function that is called when the form is submitted. */
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {Iferrors ? (
                <div
                  className={styles.errorStyle}
                  role='alert'
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    backgroundColor: '#f8d7da',
                  }}
                >
                  {Iferrors}
                </div>
              ) : null}
              {errors.email && touched.email && errors.email ? (
                <>
                  <Feilds
                    label='Email'
                    placeholder={errors.email && touched.email && errors.email}
                    inputType='text'
                    name='email'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.email}
                    errors='email'
                    classNameProp={styles.errorStyle}
                  />
                </>
              ) : (
                <Feilds
                  label='Email'
                  placeholder='Enter your Email '
                  inputType='text'
                  name='email'
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email}
                  errors='email'
                  classNameProp={styles.inputField}
                />
              )}

              {errors.password && touched.password && errors.password ? (
                <>
                  <Feilds
                    label='Password'
                    placeholder={
                      errors.password && touched.password && errors.password
                    }
                    name='password'
                    inputType='password'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    errors='password'
                    classNameProp={styles.errorStyle}
                  />
                </>
              ) : (
                <>
                  <Feilds
                    label='Password'
                    placeholder='Enter your password'
                    name='password'
                    inputType='password'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    errors='password'
                    classNameProp={styles.inputField}
                  />
                </>
              )}
              <Button action='Sign in' />
            </form>
          )}
        </Formik>
      </Card>
    </>
  )
}

export default SchoolLogin
