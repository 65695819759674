import React, {useState, useEffect} from 'react'
import Navbar from './Navbar'

const Header = () => {

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });
  }, []);

  return (
    <>
    
      <Navbar />
    </>
  )
}

export default Header
