import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import cookie from 'js-cookie'
import Layout from '../Layout/Layout'
import styles from '../../styles/SearchResults.module.css'
import DashboardSidebar from './DashboardSidebar'
import LoginFirst from '../LoginFirst/LoginFirst'
import SpeedDialTooltipOpen from './NavigateDial/SpeedNavigate'
import ArticleIcon from '@mui/icons-material/Article'
import StarsIcon from '@mui/icons-material/Stars'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import { useMediaQuery } from '@mui/material'
import useAuth from 'src/utils/useAuth'



const LayoutDashboard = ({ children }) => {

  
  const { token, decoded } = useAuth()
  const email = decoded.username
 
  const actions = [
    { icon: <LogoutIcon />, name: 'Logout', link: '/Dashboard/logout' },
    { icon: <DashboardIcon />, name: 'Dashboard', link: '/Dashboard' },
    {
      icon: <ArticleIcon />,
      name: 'Applications',
      link: '/Dashboard/applied-schools',
    },
    {
      icon: <StarsIcon />,
      name: 'Shortlist',
      link: '/Dashboard/shortlisted-schools',
    },
    {
      icon: <SentimentSatisfiedAltIcon />,
      name: 'Profile',
      link: '/Dashboard/my-profile',
    },
  ]

  const isSmallScreen = useMediaQuery('(max-width:900px)')
  let displayNone
  if (isSmallScreen) {
      displayNone = 'd-none'
   } else {
      displayNone = ''
   }



  

  const [isLogged, setIsLogged] = React.useState(false)

  React.useEffect(() => {
    if (token) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }, [token])

  return (
    <Typography component='div'>
      {isLogged ? (
        <Layout>
          <Typography component='div' className={styles.mainContainer}>
            <Container maxWidth='xl'>
              <Grid container direction='row'>
                <Grid item lg={3} md={4} sm>
                  <div className={`d-lg-flex ${displayNone}`}>
                    <DashboardSidebar />
                  </div>
                </Grid>
                <Grid item lg={9} md={8} sm={12} xs={12}>
                  <main>{children}</main>
                </Grid>
              </Grid>
            </Container>
          </Typography>
          <SpeedDialTooltipOpen actions= {actions} />
        </Layout>
      ) : (
        <>
          <LoginFirst />
        </>
      )}
    </Typography>
  )
}

export default LayoutDashboard
