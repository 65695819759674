import React, { Suspense } from "react";
import HeroSection from "../HeroBar/HeroSection";
import Footer from "../Partials/Footer/Footer";
import Header from "../Partials/Header/Header";
import PartnerSchools from "../PartnerSchools/PartnerSchools";
import styles from "src/styles/Home.module.css";
import ServicesComponent from "../Services/ServicesComponent";
import AddYourSchoolComponent from "../AddSchools/AddYourSchoolComponent";
import AboutUsComponent from "../AboutUs/AboutUsComponent";
import Testimonial from "../Testimonial/Testimonial";
import ChatwootWidget from "../ChatwootWidget/ChatwootWidget";
import { Helmet } from "react-helmet";
import "src/styles/Home.module.css";
import Navbar from "../MarketingPage/Navbar";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

const HomeComponent = () => {
  useEffect(() => {
      Aos.init({duration: 1500})
  }, [])
  return (
    <Suspense
      fallback={
        <div>
          <h1>Loading...</h1>
        </div>
      }
    >
      <div className={styles.container}>
        <Helmet>
          <title>AdmissionPedia - Leading Indian School Admissions platform </title>
          <meta
            name="description"
            content="Find the right school for your child. Research, Apply and Track Admissions anywhere, anytime."
          />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
        <Navbar position="sticky"/>
        <main className={styles.main}>
          <HeroSection />
          <ServicesComponent />
          <PartnerSchools />
          <AddYourSchoolComponent />
          <AboutUsComponent />
          <Testimonial />
        </main>
        <ChatwootWidget />
        <Footer />
      </div>
    </Suspense>
  );
};

export default HomeComponent;
