import React from 'react'
import SearchSchool from '../Search/SearchSchool'

const HeroSection = () => {
  return (
    <>
      <SearchSchool />
    </>
  )
}

export default HeroSection
