import Logo12 from "src/assets/SchoolLogos/logo12.jpg";
import Logo13 from "src/assets/SchoolLogos/logo13.png";

const Testimonials = [
  {
    id: 1,
    name: 'Sr. Pushplatha ',
    title: 'Principal St Ursula’s Anglo Indian School, Chennai',
    image: Logo12,
    review:
      'AdmissionPedia was user-friendly. It was customizable in any situation by the technical team. All the modules were made exclusively for St.Ursula\'s and the reports part was outstanding.',
  },
  {
    id: 2,
    name: 'Dr. Snigdha Kadam',
    title: 'Royal Girls High School, Thane',
    image: Logo13,
    review:
      'The software is very user friendly. The team is quick to respond & resolve any issues faced by the parents or at the backend. We look forward to working with them in the future.',
  },
  // {
  //   id: 3,
  //   name: 'School name 3',
  //   image: '/logo3.png',
  //   review:
  //     'Admission Pedia is a great platform for students to get admission in the best schools. I am very happy with the service.',
  // },
]

export default Testimonials
