import React from "react";
import PageLayout from "../../../components/SchoolDashboard/Layout/PageLayout";
import cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useRouter } from "../../../hooks/useRouter";
import { jsPDF } from "jspdf";
import { API_URL } from "../../../utils/constant";

const generatePDF = () => {
  const report = new jsPDF("portrait", "pt", "a4");
  report.html(document.querySelector("#report")).then(() => {
    report.save("student-details.pdf");
  });
};

const ApplicationList = () => {
  const token = cookie.get("token"); // This is the token from the cookie
  const decoded = token ? jwt_decode(token) : null;
  const schoolId = decoded?.school_id; // This is the school id from the token payload
  const [values, setFieldsValue] = useState([]);

  const router = useRouter();

  /* This useEffect is fetching the school data from the API and setting the school state. */
  useEffect(() => {
    const fetchSchools = async () => {
      /* Fetching the data from the API. */

      if (!router.query.id) return;

      const res = await fetch(
        `${API_URL}/forms/submissionsById?submission_id=${router.query.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json(); // data is an array of schools from the API

      console.log(">>> Data", data);

      if (data.length && data[0] && data[0].values) {
        setFieldsValue(data[0].values);
      }
    };
    fetchSchools();
  }, [schoolId, token]); // This useEffect will run when the schoolId or token changes

  return (
    <>
      <PageLayout>
        <div className="conatiner">
          <div className="row mt-5 pt-5">
            <div className="row mt-lg-5">
              <div className="card rounded-3 p-lg-5 shadow">
                <div className="container" id="report">
                  {values?.map((value, index) => {
                    return (
                      <div
                        className="row"
                        
                        // style={{
                        //   width: "100%",
                        //   height: "100%",
                        // }}
                      >
                        <div className="col">
                          <div
                            className="p-4 fs-6 fw-bold bg-opacity-10 "
                            style={{
                              backgroundColor: "rgba(251, 193, 213, 0.2)",
                            }}
                            key={index}
                          >
                            <div>{value.fieldName}</div>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="py-4 fs-6 border-bottom" key={index}>
                            <div>
                              {value.specialType === "class"
                                ? value.className
                                : value.field_value}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  onClick={generatePDF}
                  type="button"
                  className="buttonRectangle"
                  style={{ fontSize: "1.2rem" }}
                >
                  Download Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default ApplicationList;
