import React from 'react'
import { useRouter } from '../../hooks/useRouter'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import SchoolIcon from '@mui/icons-material/School'
import PageLayout from '../../components/GetStarted/Layout/PageLayout'
import FormHead from '../../components/GetStarted/Form/FormHead'
import FormLayout from '../../components/GetStarted/Layout/FormLayout'
import { API_URL } from '../../utils/constant'

const Confirmation = () => {
  /* Setting the state of the component. */
  const [schools, setSchools] = React.useState(null)
  const [searchResults, setSearchResults] = React.useState(null)

  /* Getting the registration number from the url. */
  const router = useRouter()
  const registrationNumber = router.query.registrationNumber

  /* Converting the board string to an integer. */
  const board = router.query.board
  const boardInt = parseInt(board)

  /* Fetching data from the API. */
  React.useEffect(() => {
    fetch(
      `${API_URL}/school/findSchool?board=${boardInt}&registrationNumber=${registrationNumber}`
    )
      .then((res) => res.json())
      .then((data) => {
        setSchools(data.data[0])
      })
  }, [boardInt, registrationNumber])

  if (schools) {
    return (
      <PageLayout>
        <FormHead itemTitle='School Found. Confirm Details' />
        <FormLayout>
          <div
            style={{ color: 'white', minHeight: '45vh' }}
            className='px-lg-5 px-4'
          >
            <div className='my-4 justify-content-between d-flex border-bottom py-1 border-dark'>
              <div className=' text-start'>School Name:</div>
              <div>{schools.name}</div>
            </div>
            <div className='my-4 justify-content-between d-flex border-bottom py-1 border-dark'>
              <div className=' text-start'>School Address:</div>
              <div>{schools.address}</div>
            </div>
            <div className='my-4 justify-content-between d-flex border-bottom py-1 border-dark'>
              <div className=' text-start'>City:</div>
              <div>{schools.location.district_name}</div>
            </div>
            <div className='my-4 justify-content-between d-flex border-bottom py-1 border-dark'>
              <div className=' text-start'>State:</div>
              <div>{schools.location.state_name}</div>
            </div>
            <div className='my-4 justify-content-between d-flex border-bottom py-1 border-dark'>
              <div className=' text-start'>Country:</div>
              <div>{schools.location.country_name}</div>
            </div>

            <div className='my-5'>
              <button
                style={{
                  backgroundColor: '#F876A7',
                  width: '100%',
                  padding: '15px',
                  border: 'none',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 2px white',
                }}
                onClick={() =>
                  //router.push('/get-started/additional-info/' + id)
                  router.push('/get-started/additional-info/' + schools.id)
                }
              >
                Confirm Details
              </button>
            </div>
            <div>Wrong school details? Contact us</div>
            <div
              style={{
                padding: '1rem 0',
                fontSize: '1rem',
                color: 'grey',
              }}
            >
              Click on the contact icon on the bottom right corner of the screen
              to chat with us.
            </div>
          </div>
        </FormLayout>
      </PageLayout>
    )
  } else {
    return (
      <PageLayout>
        <div className=' centering pt-5 mt-5'>
          <SchoolIcon style={{ fontSize: '100px', color: '#F876A7' }} />
        </div>

        <div>
          {searchResults ? (
            <div
              style={{
                color: 'white',
                fontSize: '20px',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              {searchResults}
            </div>
          ) : (
            <div
              style={{
                color: 'white',
                fontSize: '20px',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              School not found
            </div>
          )}
        </div>

        <div
          style={{
            color: 'grey',
            fontSize: '14px',
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          Your school details are not in our database. Please add your school to
          continue.
        </div>

        <div
          style={{ color: 'white', minHeight: '45vh' }}
          className='px-lg-5 px-4'
        >
          <div className='my-5'>
            <button
              style={{
                backgroundColor: '#F876A7',
                width: '100%',
                padding: '15px',
                border: 'none',
                borderRadius: '5px',
                boxShadow: '0px 0px 2px white',
                display: 'flex',
                textAlign: 'center',
              }}
              className='centering'
              onClick={() => router.push('/get-started/add-school')}
            >
              <ControlPointIcon
                style={{
                  fontSize: '25px',
                  color: 'white',
                  marginRight: '10px',
                }}
              />
              Add your school
            </button>
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default Confirmation
