import React from 'react'
import { Typography } from '@mui/material'
import cookies from 'js-cookie'
import LayoutDashboard from '../../../components/Dashboard/Layout'

const Apply = () => {
  return (
    <Typography component='div'>
      <LayoutDashboard>
        <div className='conatiner'>
          <div className='row mt-5'>
            <div className='row mt-lg-5'>
              <div className='card w-75 m-auto shadow'>
                <div className='card-body'>
                  <h5 className='card-title'>Logout</h5>
                  <div className='card-text my-2'>
                    Are you sure you want to logout?
                  </div>
                  <button
                    className='btn btn-primary mt-4'
                    style={{
                      backgroundColor: '#f50057',
                      border: 'none',
                      borderRadius: '5px',
                      width: '100%',
                    }}
                    onClick={() => {
                      localStorage.removeItem('token')
                      cookies.remove('token')
                      window.location.href = '/login'
                    }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDashboard>
    </Typography>
  )
}

export default Apply
