import * as React from 'react'
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Backdrop from '@mui/material/Backdrop'
import { styled } from '@mui/material/styles'
import CreateIcon from '@mui/icons-material/Create'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Fade, Modal } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import CloseIcon from '@mui/icons-material/Close'
import jwt_decode from "jwt-decode";
import Moment from 'moment'
import { Formik } from 'formik'
import * as Yup from 'yup'
import cookie from 'js-cookie'
import { API_URL } from '../../../utils/constant'

const Switcher = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const columns = [
  {
    id: 'Id',
    label: 'Id',
    minWidth: 0,
    align: 'right',
  },
  { id: 'Class', label: 'Class\u00a0', minWidth: 80 },
  {
    id: 'StartDate',
    label: 'Start Date',
    minWidth: 150,
    align: 'right',
  },
  {
    id: 'EndDate',
    label: 'End Date',
    minWidth: 150,
    align: 'right',
  },
  {
    id: 'Action',
    label: 'Action',
    minWidth: 90,
    align: 'right',
  },
]

function createData(Id, Class, StartDate, EndDate, Status) {
  return { Id, Class, StartDate, EndDate, Status }
}

const Configure = () => {
  const [schools, setSchools] = React.useState([])
  const [modalOpen, setModalOpen] = React.useState({})
  const [admissionForAllSchool, setAdmissionForAllSchool] =
    React.useState(false)
  const token = cookie.get('token')
  const decoded = token ? jwt_decode(token) : null
  const schoolId = decoded.school_id

  /* This useEffect is fetching the school data from the API and setting the school state. */
  useEffect(() => {
    const fetchSchools = async () => {
      const res = await fetch(`${API_URL}/school/${schoolId}`)
      const data = await res.json() // data is an array of schools from the API
      setSchools(data) // setting the school state
    }
    fetchSchools()
  }, [modalOpen, admissionForAllSchool, schoolId])

  const allClass =
    schools &&
    schools.classes?.map((item) => {
      // class name
      const className = item.name
      // class start date
      const classStartDate = Moment(item.start_date).format('ll')
      // class end date
      const classEndDate = Moment(item.end_date).format('ll')
      // class admission status
      const classAdmissionStatus = item.admission_status
      const classId = item.id
      /* Creating a new class object and returning it. */
      return createData(
        classId,
        className,
        classStartDate,
        classEndDate,
        classAdmissionStatus
      )
    })

  const rows = allClass || []

  const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #F876A7',
    boxShadow: 24,
    p: 4,
    bottom: 'auto',
    overflow: 'scroll',
  }

  const handleAdmissionForAllSchool = () => {
    if (schools.admission_status === 'active') {
      //call a new API to close the admission status
      const data = {
        classIds: [schools.classes.map((item) => item.id)],
        schoolId: decoded.school_id,
        startDate: schools.classes[0].start_date,
        endDate: schools.classes[0].end_date,
        closeAdmission: true,
      }
      fetch(`${API_URL}/class/admission/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          setAdmissionForAllSchool(false)
        })
        .catch((err) => {
          alert(err)
        })
      setAdmissionForAllSchool(true)
    } else {
      setAdmissionForAllSchool(true)
    }
  }

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '30px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0',
      }}
    >
      <TableContainer sx={{ maxHeight: 550 }}>
        <div className=' d-flex justify-content-between'>
          <div className='p-3 d-flex w-100'>
            <div className=' align-content-center justify-content-center p-2 mt-2 w-100'>
              <div className=' d-flex justify-content-between w-100'>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: 'gray',
                    width: '100%',
                  }}
                >
                  Admission Status:{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      color: 'green',
                    }}
                  >
                    {schools && schools.admission_status === 'active' ? (
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                          color: 'green',
                        }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.2rem',
                          color: 'red',
                        }}
                      >
                        Inactive
                      </span>
                    )}
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      color: '#F67DAA',
                      float: 'right',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switcher
                          sx={{ m: 1 }}
                          checked={
                            schools && schools.admission_status === 'active'
                              ? true
                              : false
                          }
                          onClick={handleAdmissionForAllSchool}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: '0.8rem',
                  color: 'gray',
                }}
              >
                Manage your admission status here. You can also set the
                admission period for each class.
              </div>
            </div>
          </div>
        </div>
        <Table stickyHeader aria-label='sticky table'>
          {schools.admission_status === 'archived' && (
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              closeAfterTransition
              BackdropComponent={Backdrop}
              open={admissionForAllSchool}
            >
              <Fade in={admissionForAllSchool}>
                <Paper sx={style} style={{ backgroundColor: 'white' }}>
                  <CloseIcon
                    style={{
                      float: 'right',
                      cursor: 'pointer',
                      color: 'gray',
                    }}
                    onClick={() => {
                      setAdmissionForAllSchool(false)
                    }}
                  />
                  <Formik
                    initialValues={{
                      startDate: '',
                      endDate: '',
                    }}
                    validate={(values) => {
                      const errors = {}
                      if (
                        !values.startDate ||
                        values.startDate === '' ||
                        values.startDate === null
                      ) {
                        errors.startDate = 'Required'
                      }

                      // start date should be less than end date use moment js to compare
                      if (
                        Moment(values.startDate).isAfter(values.endDate) ===
                          true ||
                        Moment(values.startDate).isSame(values.endDate) === true
                      ) {
                        errors.endDate =
                          'End date should be greater than start date'
                      }

                      if (
                        !values.endDate ||
                        values.endDate === '' ||
                        values.endDate === null
                      ) {
                        errors.endDate = 'Required'
                      }
                      return errors
                    }}
                    onSubmit={(values) => {
                      const data = {
                        classIds: [rows.map((row) => row.Id)],
                        schoolId: decoded.school_id,
                        startDate: values.startDate,
                        endDate: values.endDate,
                      }
                      fetch(`${API_URL}/class/admission/update`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          authorization:
                            'Bearer ' + localStorage.getItem('token'),
                        },
                        body: JSON.stringify(data),
                      })
                        .then((res) => {
                          setAdmissionForAllSchool(false)
                        })
                        .catch((err) => {
                          alert(err)
                        })
                    }}
                    validationSchema={Yup.object().shape({
                      startDate: Yup.string().required('Required'),
                      endDate: Yup.string().required('Required'),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        errors,
                        touched,
                      } = props
                      return (
                        <form onSubmit={handleSubmit}>
                          <div
                            style={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              color: 'gray',
                            }}
                          >
                            Open Admission for all classes
                          </div>
                          <div
                            style={{
                              fontSize: '0.8rem',
                              color: 'gray',
                            }}
                          >
                            Set the admission period for all classes
                          </div>
                          <hr />
                          <div className='row mb-5'>
                            <div className='col-12 mt-4'>
                              <label>Start Date</label>
                              <div className='form-group'>
                                <input
                                  type='date'
                                  name='startDate'
                                  placeholder='Enter Start Date'
                                  value={values.startDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f77eaa',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    width: '100%',
                                    color: 'gray',
                                  }}
                                />
                                {
                                  <div
                                    style={{
                                      color: 'red',
                                      fontSize: '0.8rem',
                                    }}
                                  >
                                    {errors.startDate && touched.startDate
                                      ? errors.startDate
                                      : null}
                                  </div>
                                }
                              </div>
                            </div>
                            <div className='col-12 mt-4'>
                              <label>End Date</label>
                              <div className='form-group'>
                                <input
                                  type='date'
                                  name='endDate'
                                  placeholder='Enter End Date'
                                  value={values.endDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f77eaa',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    width: '100%',
                                    color: 'gray',
                                  }}
                                />
                                {
                                  <div
                                    style={{
                                      color: 'red',
                                      fontSize: '0.8rem',
                                    }}
                                  >
                                    {errors.endDate && touched.endDate
                                      ? errors.endDate
                                      : null}
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className='row d-flex'>
                            <button
                              type='submit'
                              className='btn btn-primary '
                              style={{
                                backgroundColor: '#f77eaa',
                                border: 'none',
                                borderRadius: '10px',
                                padding: '10px 20px',
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                </Paper>
              </Fade>
            </Modal>
          )}
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === 'Id' ? (
                    <div
                      style={{
                        display: 'none',
                      }}
                    >
                      {column.label}
                    </div>
                  ) : (
                    <div>{column.label}</div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <>
                  {modalOpen[row.Id] && (
                    <Modal
                      aria-labelledby='transition-modal-title'
                      aria-describedby='transition-modal-description'
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      open={modalOpen[row.Id]}
                    >
                      <Fade in={modalOpen[row.Id]}>
                        <Paper sx={style} style={{ backgroundColor: 'white' }}>
                          <CloseIcon
                            style={{
                              float: 'right',
                              cursor: 'pointer',
                              color: 'gray',
                            }}
                            onClick={() => {
                              setModalOpen({
                                ...modalOpen,
                                [row.Id]: false,
                              })
                            }}
                          />
                          <Formik
                            initialValues={{
                              startDate: '' || row.StartDate,
                              endDate: '' || row.EndDate,
                            }}
                            onSubmit={(values) => {
                              const data = {
                                classIds: [row.Id.toString()],
                                schoolId: decoded.school_id,
                                startDate: values.startDate,
                                endDate: values.endDate,
                              }
                              fetch(`${API_URL}/class/admission/update`, {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                  authorization:
                                    'Bearer ' + localStorage.getItem('token'),
                                },
                                body: JSON.stringify(data),
                              })
                                .then((res) => {
                                  setModalOpen({
                                    ...modalOpen,
                                    [row.Id]: false,
                                  })
                                })
                                .catch((err) => {
                                  alert(err)
                                })
                            }}
                            validationSchema={Yup.object().shape({
                              startDate: Yup.string().required('Required'),
                              endDate: Yup.string().required('Required'),
                            })}
                          >
                            {(props) => {
                              const {
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                              } = props
                              return (
                                <form onSubmit={handleSubmit}>
                                  <div className='row mb-5'>
                                    <div
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                        color: 'gray',
                                      }}
                                    >
                                      Open Admission for {row.Class}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'gray',
                                      }}
                                    >
                                      Set the admission period for {row.Class}
                                    </div>
                                    <div className='col-12 mt-4'>
                                      <label>Start Date</label>
                                      <div className='form-group'>
                                        <input
                                          type='date'
                                          name='startDate'
                                          placeholder='Enter Start Date'
                                          defaultValue={row.StartDate}
                                          value={values.startDate}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          style={{
                                            backgroundColor: 'white',
                                            border: '1px solid #f77eaa',
                                            borderRadius: '5px',
                                            padding: '10px 20px',
                                            width: '100%',
                                            color: 'gray',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-12 mt-4'>
                                      <label>End Date</label>
                                      <div className='form-group'>
                                        <input
                                          type='date'
                                          name='endDate'
                                          placeholder='Enter End Date'
                                          value={values.endDate}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          style={{
                                            backgroundColor: 'white',
                                            border: '1px solid #f77eaa',
                                            borderRadius: '5px',
                                            padding: '10px 20px',
                                            width: '100%',
                                            color: 'gray',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row d-flex'>
                                    <button
                                      type='submit'
                                      className='btn btn-primary '
                                      style={{
                                        backgroundColor: '#f77eaa',
                                        border: 'none',
                                        borderRadius: '10px',
                                        padding: '10px 20px',
                                      }}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </form>
                              )
                            }}
                          </Formik>
                        </Paper>
                      </Fade>
                    </Modal>
                  )}
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.Id + 1}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <>
                          <TableCell
                            key={column.id + column.label}
                            align={column.align}
                          >
                            {value === 'Invalid date' ? (
                              <div className='text-danger'>Not Set</div>
                            ) : (
                              <div className=' fw-semibold'>
                                {column.id === 'Id' ? (
                                  // dont show the id
                                  <div
                                    style={{
                                      display: 'none',
                                    }}
                                  >
                                    {value}
                                  </div>
                                ) : (
                                  <div>
                                    {column.id === 'StartDate' ||
                                    column.id === 'EndDate' ? (
                                      <div>
                                        <div>
                                          {value}{' '}
                                          <CreateIcon
                                            style={{
                                              color: 'gray',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              setModalOpen({
                                                ...modalOpen,
                                                [row.Id]: true,
                                              })
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div>{value}</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {column.id === 'Action' && (
                              <div
                                style={{
                                  display: 'flex',
                                  color: '#F67DAA',
                                  float: 'right',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Switcher
                                      sx={{ m: 1 }}
                                      onClick={() => {
                                        if (row.Status === 0) {
                                          if (
                                            row.StartDate &&
                                            row.EndDate === 'Invalid date'
                                          ) {
                                            setModalOpen({
                                              ...modalOpen,
                                              [row.Id]: true,
                                            })
                                          } else {
                                            const data = {
                                              classIds:
                                                [row.Id.toString()] || row.Id,
                                              schoolId: decoded.school_id,
                                              startDate: row.StartDate,
                                              endDate: row.EndDate,
                                              closeAdmission: false,
                                            }
                                            fetch(
                                              `${API_URL}/class/admission/update`,
                                              {
                                                method: 'POST',
                                                headers: {
                                                  'Content-Type':
                                                    'application/json',
                                                  authorization:
                                                    'Bearer ' +
                                                    localStorage.getItem(
                                                      'token'
                                                    ),
                                                },
                                                body: JSON.stringify(data),
                                              }
                                            )
                                              .then((res) => {
                                                setModalOpen({
                                                  ...modalOpen,
                                                  [row.Id]: false,
                                                })
                                              })
                                              .catch((err) => {
                                                alert(err)
                                              })
                                          }
                                        } else {
                                          const data = {
                                            classIds:
                                              [row.Id.toString()] || row.Id,
                                            schoolId: decoded.school_id,
                                            startDate: row.StartDate,
                                            endDate: row.EndDate,
                                            closeAdmission: true,
                                          }
                                          fetch(
                                            `${API_URL}/class/admission/update`,
                                            {
                                              method: 'POST',
                                              headers: {
                                                'Content-Type':
                                                  'application/json',
                                                authorization:
                                                  'Bearer ' +
                                                  localStorage.getItem('token'),
                                              },
                                              body: JSON.stringify(data),
                                            }
                                          )
                                            .then((res) => {
                                              setModalOpen({
                                                ...modalOpen,
                                                [row.Id]: false,
                                              })
                                            })
                                            .catch((err) => {
                                              alert(err)
                                            })
                                        }
                                      }}
                                      checked={
                                        row.Status === 1 ? true : false || false
                                      }
                                    />
                                  }
                                />
                              </div>
                            )}
                          </TableCell>
                        </>
                      )
                    })}
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default Configure
