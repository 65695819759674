import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useRouter } from '../../../hooks/useRouter';
import PageLayout from '../../../components/SchoolDashboard/Layout/PageLayout'
import { API_URL } from '../../../utils/constant'

const Index = () => {
  const router = useRouter()
  const classId = router.query.classId
  const schoolId = router.query.schoolId
  const [resMsg, setResMsg] = React.useState('')

  return (
    <>
      <PageLayout>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-12 mt-5 pt-5'>
              <div className=' bg-white p-5 shadow rounded-4'>
                <div className='card-body'>
                  <Formik
                    initialValues={{
                      startDate: '',
                      endDate: '',
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const data = {
                        schoolId: schoolId,
                        classIds: classId,
                        startDate: values.startDate,
                        endDate: values.endDate,
                      }

                      axios
                        .post(`${API_URL}/class/admission/update`, data, {
                          headers: {
                            authorization: `Bearer ${localStorage.getItem(
                              'token'
                            )}`,
                          },
                        })
                        .then((res) => {
                          console.log(res)
                          setResMsg(res.data.message)
                          setSubmitting(false)
                        })
                        .catch((err) => {
                          setResMsg(err.response.data.message)
                          setSubmitting(false)
                        })
                    }}
                    validationSchema={Yup.object().shape({
                      startDate: Yup.string().required('Required'),
                      endDate: Yup.string().required('Required'),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className='row mb-5'>
                            {resMsg && (
                              <div
                                className={`alert alert-success alert-dismissible fade show`}
                                role='alert'
                              >
                                {resMsg}
                                <button
                                  type='button'
                                  className='btn-close'
                                  data-bs-dismiss='alert'
                                  aria-label='Close'
                                ></button>
                              </div>
                            )}
                            <div className='col-md-6 pr-1'>
                              <label>Start Date</label>
                              <div className='form-group'>
                                <input
                                  type='date'
                                  name='startDate'
                                  placeholder='Enter Start Date'
                                  value={values.startDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.startDate && touched.startDate
                                      ? 'text-input error'
                                      : 'text-input'
                                  }
                                  style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f77eaa',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    width: '100%',
                                    color: 'gray',
                                  }}
                                />
                                {errors.startDate && touched.startDate && (
                                  <div className='input-feedback text-end text-danger'>
                                    {errors.startDate}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-md-6 pl-1'>
                              <label>End Date</label>
                              <div className='form-group'>
                                <input
                                  type='date'
                                  name='endDate'
                                  placeholder='Enter End Date'
                                  value={values.endDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.endDate && touched.endDate
                                      ? 'text-input error'
                                      : 'text-input'
                                  }
                                  style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #f77eaa',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    width: '100%',
                                    color: 'gray',
                                  }}
                                />
                                {errors.endDate && touched.endDate && (
                                  <div className='input-feedback text-end text-danger'>
                                    {errors.endDate}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='row d-flex'>
                            <button
                              type='submit'
                              className='btn btn-primary mt-5'
                              style={{
                                backgroundColor: '#f77eaa',
                                border: 'none',
                                borderRadius: '10px',
                                padding: '10px 20px',
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default Index
