import React from "react";
import {  Grid } from "@mui/material";
import SideBar from "../../components/SearchResults/Sidebar/SideBar";
import Layout from "../../components/Layout/Layout";
import styles from "../../styles/SearchResults.module.css";
import Results from "../../components/SearchResults/Results";
import { FilterProvider } from "../../context/FilterContext";
import { Helmet } from "react-helmet";

const SearchResults = () => {
  return (
    <FilterProvider>  
       <Helmet>
        <title>Find Schools</title>
       </Helmet>
        <Layout>
          <div className={styles.mainContainer}> 
          <div className={styles.gridWrapper}>
            <Grid container direction="row" columnSpacing={6}>
                <Grid item lg={3} md={4} sm>
                  <SideBar />
                </Grid>
                <Grid item lg={9} md={8} sm={12} xs={12}>
                  <Results />
                  {/* <div
                  className={`${styles.cardComponent}  mx-auto `}
                  style={{
                    width: '80%',
                    borderRadius: '30px',
                    cursor: 'pointer',
                  }}
                >
                  <SearchSchools />
                </div> */}
                </Grid>
              </Grid> 
            </div>     
                     
          </div>
        </Layout>  
    </FilterProvider>
  );
};

export default SearchResults;
