import React from 'react'
import AuthenticationPageLayout from '../../components/Authentication/Layout/AuthenticationPageLayout'
import Layout from '../../components/Layout/Layout'
import ForgetPassword from '../../components/Authentication/ForgetPassword/ForgetPassword'

const Index = () => {
  return (
    <Layout>
      <AuthenticationPageLayout>
        <br />
        <br />
        <br />
        <br />
        <br />
        <ForgetPassword />
      </AuthenticationPageLayout>
    </Layout>
  )
}

export default Index
