import React from 'react'
import { Grid } from '@mui/material'
import Header from '../../ForSchool/Partials/Header'
import ChatwootWidget from '../../ChatwootWidget/ChatwootWidget'
import styles from '../../../styles/GetStarted.module.css'

const PageLayout = ({ children }) => {
  return (
    <div className={styles.add_bg_img}>
      <Header />
      <div className={styles.mainContainer}>
        <Grid container direction='column'>
          {children}
        </Grid>
        <ChatwootWidget />
      </div>
    </div>
  )
}

export default PageLayout
