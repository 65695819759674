import { Formik } from 'formik'
import React from 'react'
import cookie from 'js-cookie'
import { API_URL } from '../../utils/constant'
import axios from 'axios'
import PageLayout from '../../components/SchoolDashboard/Layout/PageLayout'

const settings = () => {
  return (
    <>
      <PageLayout>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mt-5 pt-5'>
              <div className=' bg-white p-5 shadow rounded-4'>
                <div className='card-body'>
                  <Formik
                    initialValues={{
                      password: '',
                      confirmPassword: '',
                    }}
                    validate={(values) => {
                      const errors = {}
                      if (!values.password) {
                        errors.password = 'Required'
                      }
                      if (!values.confirmPassword) {
                        errors.confirmPassword = 'Required'
                      }
                      if (
                        values.password &&
                        values.confirmPassword &&
                        values.password !== values.confirmPassword
                      ) {
                        errors.confirmPassword = 'Passwords do not match'
                      }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const data = {
                        oldPassword: values.password,
                        newPassword: values.confirmPassword,
                      }
                      axios
                        .post(`${API_URL}/user/school-changePassword`, data, {
                          headers: {
                            authorization: `Bearer ${cookie.get('token')}`,
                          },
                        })
                        .then((res) => {
                          console.log(res)
                          if (res.status === 200) {
                            alert('Password changed successfully')
                          }
                        })
                        .catch((err) => {
                          console.log(err)
                          alert('Something went wrong')
                        })
                      setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className='row mt-4'>
                          <div className='col-md-6 pr-1'>
                            <div className='form-group'>
                              <label>Current Password</label>
                              <input
                                type='password'
                                className='form-control'
                                placeholder='Enter your current password'
                                value={values.password}
                                onChange={handleChange}
                                handleBlur={handleBlur}
                                name='password'
                              />
                            </div>
                          </div>
                          <div className='col-md-6 pl-1'>
                            <div className='form-group'>
                              <label>New Password</label>
                              <input
                                type='password'
                                className='form-control'
                                placeholder='Enter your new password'
                                value={values.confirmPassword}
                                onChange={handleChange}
                                handleBlur={handleBlur}
                                name='confirmPassword'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <button
                            type='submit'
                            className='btn btn-primary mt-5'
                            style={{
                              backgroundColor: '#f77eaa',
                              border: 'none',
                              borderRadius: '10px',
                              padding: '10px 20px',
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default settings
