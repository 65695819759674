import React from 'react'
import Services from '../../data/Home/Services'
import styles from 'src/styles/AddingSchool.module.css'
import 'src/styles/AddingSchool.module.css'

const ServicesComponent = () => {
  return (
    <>
      <div  className=' w-100 px-lg-4 px-0 centering align-content-center justify-content-center py-5 py-lg-0'>
        <div className='mb-5 d-block d-lg-none  centering'>
          <h1 className={styles.services}>Our Services</h1>
          <div
            style={{
              fontFamily: 'Space Grotesk',
              color: 'gray',
            }}
          >
            Service we provide for parents and students
          </div>
        </div>
        <div data-aos="fade-up" className={`${styles.servicesContainer} row w-100 `}>
          {Services.map((service, index) => (
            <div className='col-md-12 col-lg-4  ' key={index + service.title + 1}>
              <div
                className='shadow rounded-5 bg m-2  hoverColor'
                style={{ height: '300px' }}
              >
                <div className='card-body p-5 '>
                  <img
                    src={service.img}
                    width={50}
                    height={50}
                    alt={service.title}
                  />
                  <div className='mt-3'>
                    <h4>{service.title}</h4>
                  </div>
                  <div className='mt-3'>{service.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ServicesComponent
