import { TurnedInOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "../../styles/PartnerSchools.module.css";
import getRandomString from "../../utils/helper";
import randomGenerationString from "../../utils/helper";
import  "src/styles/PartnerSchools.module.css";

import Logo1 from "src/assets/SchoolLogos/logo1.png";
import Logo2 from "src/assets/SchoolLogos/logo2.png";
import Logo3 from "src/assets/SchoolLogos/logo3.png";
import Logo4 from "src/assets/SchoolLogos/logo4.png";
import Logo5 from "src/assets/SchoolLogos/logo5.png";
import Logo6 from "src/assets/SchoolLogos/logo6.png";
import Logo8 from "src/assets/SchoolLogos/logo8.png";
import Logo9 from "src/assets/SchoolLogos/logo9.png";
import Logo10 from "src/assets/SchoolLogos/logo10.png";
import Logo11 from "src/assets/SchoolLogos/logo11.png";

const PartnerSchools = () => {
  const Logo = [
    {
      id: 1,
      name: " Cluny Convent High School",
      image: Logo1,
      city: "Bengaluru",
    },
    {
      id: 2,
      name: "Delhi Public School",
      image: Logo2,
      city: "",
    },
    {
      id: 3,
      name: "Indirapuram Public School ",
      image: Logo3,
      city: "Indirapuram",
    },
    { id: 4, name: "Morning Bells Academy ", image: Logo4, city: "Kolkata" },
    { id: 5, name: " Navy Children School ", image: Logo5, city: "Mumbai" },
    {
      id: 6,
      name: "Nirmalamatha Convent Matric Hr. Sec. School ",
      image: Logo6,
      city: "Coimbatore",
    },
    {
      id: 8,
      name: "Dr. Vijaypat Singhania School",
      image: Logo8,
      city: "Vapi",
    },
    { id: 9, name: "St. Joseph's Academy  ", image: Logo9, city: "Dehradun" },
    { id: 10, name: "St. Patrick's Academy", image: Logo10, city: "Meerut" },

    {
      id: 11,
      name: "Nirmala Rani High School ",
      image: Logo11,
      city: "Bengaluru",
    },
  ];

  /* A responsive object that is used to set the number of items to be displayed on the screen. */
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 5,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <>
      <Typography
        component="div"
        className={styles.carousel_container}
        style={{
          paddingTop: "20px",
        }}
      >
        <Typography component="div">
          <Typography
            style={{
              fontSize: "30px",
              textAlign: "center",
              marginBottom: "40px",
              color: "white",
            }}
          >
            Our Partner Schools
          </Typography>
        </Typography>
        <Carousel
          additionalTransfrom={0}
          autoPlay
          autoPlaySpeed={4000}
          centerMode={false}
          containerClass="container-with-dots"
          customTransition="all 3s linear"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderDotsOutside={true}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          slidesToSlide={1}
          swipeable
          ssr={TurnedInOutlined}
          className={styles.carousel}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {Logo.map((item) => (
            <Typography
              component="div"
              key={
                item.id +
                getRandomString(item.id, 5, randomGenerationString) +
                1
              }
              className={styles.imageContainerStyle}
              style={{
                padding: "5px",
              }}
            >
              <img
                src={item.image}
                width={170}
                height={160}
                alt={item.name}
                className={styles.imageStyle}
                style={{
                  zIndex: -1,
                }}
              />

              <h3
                style={{
                  fontSize: "1rem",
                  fontFamily: "Space Grotesk",
                  paddingTop: "20px",
                  color: "white",
                }}
              >
                {item.name}
              </h3>

              <h3
                style={{
                  fontSize: "1rem",
                  fontFamily: "Space Grotesk",
                  color: "white",
                }}
              >
                {item.city}
              </h3>
            </Typography>
          ))}
        </Carousel>
      </Typography>
    </>
  );
};

export default PartnerSchools;
