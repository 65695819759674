import { Link }  from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import styles from '../../../styles/marketing.module.css'

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });
  }, []);

  return (
    <nav className={`${styles.Navbar} ${scroll ? "bg-gray" : null}`}>      
         
                <div className='navbarItem'>
                  <Link
                    to='/'
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.91)',
                    }}
                    className={styles.LogoStyle}
                  >
                    <span
                      style={{
                        color: 'white',
                      }}
                    >
                      Admission
                    </span>
                    <span className={styles.pedia}>Pedia</span>
                  </Link>
                </div>

                
                <div>
                  <Link to='/get-started'>
                    <button className={styles.SignInButton}>
                      <span>Get Started</span>
                    </button>
                  </Link>
                </div>
            
           
      </nav>
   
  )
}

export default Navbar
