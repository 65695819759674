import { Container, Typography } from '@mui/material'
import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import Testimonials from '../../data/Home/Testimonials'
import styles from '../../styles/Testimonial.module.css'
import getRandomString from '../../utils/helper'
import randomGenerationString from '../../utils/helper'
import ReviewImg from "src/assets/review/review.png";

const Testimonial = () => {
  return (
    <div data-aos="fade-up">
      <Typography
        component='div'
        className={styles.carousel_container}
        style={{
          paddingTop: '20px',
          paddingBottom: '50px',
          marginTop: '50px',
          position: 'relative',
        }}
      >
        <Typography component='div'>
          <Typography
            style={{
              fontSize: '30px',
              textAlign: 'center',
              color: '#000000',
            }}
          >
            What our Users Say About Us
          </Typography>
        </Typography>
        <Container className='d-lg-flex justify-content-center align-items-center d-block mt-4 centering' style={{gap: '5rem'}}>
          {Testimonials.map((item) => (
            <Typography
              component='div'
              key={
                item.id +
                getRandomString(item.id, 5, randomGenerationString) +
                1
              }
              className={styles.imageContainerStyle}
            >
              <Typography component='div' className={styles.reviewStyle}>
                <div className=' d-flex centering'>
                  <img
                    src={ReviewImg}
                    width={40}
                    height={60}
                    className='my-4'
                    alt='quote'
                  />{' '}
                  <img
                    src={ReviewImg}
                    width={40}
                    height={60}
                    className='my-4'
                    alt='quote'
                  />
                </div>
                <Typography component='div' className={styles.reviewTextStyle}>
                  {item.review}
                </Typography>
              </Typography>

              <img
                src={item.image}
                width={80}
                height={80}
                alt={item.name}
                className={styles.imageStyle}
                style={{
                 
                  border: '4px solid white',
                  marginTop: '-40px',
                  position: 'relative',
                  boxShadow: '0px 0px 5px 0px #000000',
                }}
              />
              <div>
                <Typography component='div' className={styles.schoolNameStyle}>
                  {item.name}
                </Typography>
              </div>

              <div>
                <Typography component='div' className={styles.titleNameStyle}>
                  {item.title}
                </Typography>
              </div>
            </Typography>
          ))}
        </Container>
      </Typography>
    </div>
  )
}

export default Testimonial
