import * as React from 'react'
import * as XLSX from 'xlsx'
import ArticleIcon from '@mui/icons-material/Article'
import cookie from 'js-cookie'
import jwt_decode from "jwt-decode";
import { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useRouter } from '../../../hooks/useRouter';
import { API_URL } from '../../../utils/constant'
import getRandomString from '../../../utils/helper'
import { Table, TableBody } from '@mui/material'

const List = () => {
  const [schools, setSchools] = React.useState([]);
  const [firstRow, setFirstRow] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowsForExcel, setRowsForExcel] = React.useState([]);
  const [headerForExcel, setHeaderForExcel] = React.useState([])
  const token = cookie.get('token')
  const decoded = token ? jwt_decode(token) : null
  const schoolId = decoded.school_id 
  const router = useRouter()


  const fetchSchools = async () => {
    const res = await fetch(
      `${API_URL}/forms/submissions?schoolId=${schoolId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const data = await res.json() ;

  
    let newRows = [];
    let tableHeader = [];
    let tableHeaderForExcel = [];


    if (data?.length)  {
      tableHeader = await Promise.all(
        data[0].values.map((fieldData) => {
          tableHeaderForExcel.push(fieldData.fieldName)
          return {
            fieldId: fieldData.field_id,
            label: fieldData.fieldName,
          }})
      );

      setHeaderForExcel(["Submission Id", ...tableHeaderForExcel])

      tableHeader = [...tableHeader, " "]
    }

    let newExcelRows = [];

    newRows = await Promise.all(data.map(async (value) => {
      const formattedValues = await Promise.all(value.values.map((value) => {


        return value.specialType === "class" ? value.className : value.field_value || "-"
      }));

      newExcelRows.push([value.id, ...formattedValues])
      return formattedValues

    }))

    setRowsForExcel(newExcelRows);
    setFirstRow(tableHeader);
    setRows(newRows);
    setSchools(data)
  }

  useEffect(() => {
    fetchSchools()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, token]) // This useEffect will run when the schoolId or token changes


  function downloadExcel() {
    const worksheet = XLSX.utils.aoa_to_sheet([headerForExcel, ...rowsForExcel])
    const csv = XLSX.utils.sheet_to_csv(worksheet)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'application-list.csv')
    link.click()

    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    // XLSX.writeFile(workbook, 'application-list.xlsx')
  }



  /* Creating a table row for each submission. */
  const testRows = schools.map((value) => {
    return [
      ...value.values.map((value) => {
        return value.specialType !== '' ? value.field_value : ''
      }),
    ]
  })


  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>
          <h3
            style={{
              fontSize: '20px',
              fontWeight: '600',
              color: 'gray',
            }}
          >
            Application List
          </h3>
        </div>
        <div>
          <button
            style={{
              border: '1px solid green',
              padding: '5px 10px',
              borderRadius: '5px',
              color: 'green',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            onClick={downloadExcel}
          >
            Export to Excel
            <ArticleIcon
              style={{
                marginLeft: '5px',
              }}
            />
          </button>
        </div>
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableBody>
              <TableRow
                hover
                role='checkbox'
                style={{
                  backgroundColor: 'lightgray',
                  fontWeight: '700',
                }}
              >
                {firstRow &&
                  firstRow.map((column) => {
                    return (
                      <TableCell
                        key={column}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  })}
              </TableRow>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    key={getRandomString(
                      10,
                      6,
                      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
                    )}
                  >
                    {row.map((column) => {
                        return (
                          <>
                            <TableCell
                              key={column}
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {column}
                            </TableCell>
                          </>
                        )
                      })}
                    <TableCell>
                      <button
                        style={{
                          border: '1px solid green',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          color: 'green',
                          backgroundColor: 'white',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // redirect to the details page
                          router.push(
                            `/school-dashboard/application-list/details?id=${rowsForExcel[index][0]}`
                          )
                        }}
                      >
                        Details
                      </button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default List
